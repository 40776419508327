import { RESET_SEARCH, SET_SEARCH } from '../actions/search';

const initialState = {
	search: null,
};

export default function searchReducer(state = initialState, action) {
	switch (action.type) {
		case SET_SEARCH:
			return action.payload.search;
		case RESET_SEARCH:
			return initialState;
		default:
			return state;
	}
}
