import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import CategoryItem from '../CategoryItem';

class HomeCategories extends Component {
	static propTypes = {
		categories: PropTypes.array,
	};

	render() {
		const { categories, allCategoriesCollection, masterProvider } = this.props;
		var Headline = 'Over 10,000 Products and 250,000 Locations';
		if (masterProvider && masterProvider.isActive) {
			Headline = masterProvider.headerHeadline || Headline;
		}
		const allCategories = {
			name: 'All Categories',
			urlSlug: 'categories',
		};

		return (
			<Fragment>
				<div className="home-categories-wrapper">
					{categories ? (
						<div className="home-categories-container">
							<div className="container">
								<div className="intro">
									<h1>{Headline}</h1>
									<div className="sub">
										<p>Please select your category</p>
									</div>
								</div>
							</div>

							<div className="container">
								<div className={`category-items-container ${categories.length <= 3 ? 'centered' : ''}`}>
									{categories.map((item, index) => (
										<div className="category-item-container" key={index}>
											<CategoryItem category={item} key={index} />
										</div>
									))}

									{categories.length !== allCategoriesCollection.length && (
										<div className="category-item-container all-categories">
											<CategoryItem category={allCategories} isManual />
										</div>
									)}
								</div>
							</div>
						</div>
					) : (
						''
					)}
				</div>
			</Fragment>
		);
	}
}

export default HomeCategories;
