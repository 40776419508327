import Script from 'next/script';

export const GTM = () => {
    return (
        <>
            <Script
                id="gtm-init"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'gtm.start': new Date().getTime(),
                            event: 'gtm.js'
                        });
                    `
                }}
            />
            <Script
                id="gtm-script"
                strategy="afterInteractive"
                src={`https://www.googletagmanager.com/gtm.js?id=GTM-PNHHSL5`}
            />
        </>
    );
};