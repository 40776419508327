import { RESET_MASTERPROVIDER, SET_MASTERPROVIDER } from '../actions/masterProvider';

const initialState = { isActive: false };

export default function masterProviderReducer(state = initialState, action) {
	switch (action.type) {
		case SET_MASTERPROVIDER:
			return action.payload.provider;
		case RESET_MASTERPROVIDER:
			return initialState;
		default:
			return state;
	}
}
