import axios from 'axios';

export const SET_ROADSIDE = 'roadside/SET_ROADSIDE';
export const RESET_ROADSIDE = 'roadside/RESET_ROADSIDE';

const apiPath = 'https://8gixkwh7dg.execute-api.us-west-2.amazonaws.com/Prod/v1/Roadside';

export const setRoadside = request => (
	
	{

	
	type: SET_ROADSIDE,
	payload: {
		request,
	},
});

const resetRoadside = () => ({
	type: RESET_ROADSIDE,
});

export const resetRoadsideRequest = () => async dispatch => {
	dispatch(resetRoadside());
};

export const getRoadsideRequest = request => async dispatch => {
	var path = `${apiPath}/servicerequest?requestid=${request}`;

	var result = await axios
		.get(path, { crossdomain: true })
		.then(response => {
			return response.data;
		})
		.catch(error => {
			console.log(`Invalid Roadside ID ${error}`);
		});

	if (result) {
		dispatch(setRoadside(result));
	}
};

export const updateRoadsideRequest = (request, orderid, status) => async dispatch => {
	console.log('UPDATE ROADSIDE', request, orderid, status);
	var path = `${apiPath}/roadside/servicerequest?requestid=${request}&orderid=${orderid}&status=${status}`;

	var result = await axios
		.put(path, { crossdomain: true })
		.then(response => {
			return response.data;
		})
		.catch(error => {
			console.log(`Invalid Roadside Update ${error}`);
		});

	if (result) {
		dispatch(setRoadside(result));
	}
};
