import Image from 'next/image';
import React from 'react';
import StarRatings from 'react-star-ratings';

import reviewsIo from '../../../static/images/logos/reviews-io.png';
import reviewsIoSquare from '../../../static/images/logos/reviewsio-logo--stacked@2x.png';
import { markdownify } from '../../../utils';
import Link from 'next/link';



function Reviews({ type, reviews, href }) {
	const word = reviews && reviews.word ? reviews.word : 'Excellent';

	const average_rating = reviews && reviews.stats ? reviews.stats.average_rating : '4.3';
	const total_reviews = reviews && reviews.stats ? reviews.stats.total_reviews : '4195+';

	const reviews_list = reviews && reviews.reviews ? reviews.reviews : static_reviews;

	return (
		<>
			{type === 'vertical' && (
				<div className="reviews sidebar">
					<div className="head">
						<h5>We Come to Your Location and Provide Our Expertise with Stellar Results</h5>
					</div>
					<div className="intro">
						<div className="items">
							<div className="item condition">
								<span>EXCELLENT</span>
							</div>

							<div className="item review-stars">
								<StarRatings
									numberOfStars={5}
									rating={4.5}
									starDimension="16px"
									starSpacing="0"
									starRatedColor="#FF9966"
									starEmptyColor="grey"
								/>
							</div>
						</div>

						<div className="items">
							<div className="item">
								<span>
									<b>{average_rating}</b> Average
								</span>
							</div>

							<div className="item">
								<span>
									<b>{total_reviews}</b> Reviews
								</span>
							</div>
						</div>	
					</div>

					<div className="body">
						{reviews_list.map((data, index) => {
							return (
								<blockquote className="blockquote" key={index}>
									<p className="mb-0">{markdownify(data.comments)}</p>
									<div className="blockquote-footer">
										<span className="mr-1">
											{data.reviewer.first_name} {data.reviewer.last_name}
										</span>
										<StarRatings
											numberOfStars={5}
											rating={data.rating}
											starDimension="14px"
											starSpacing="0"
											starRatedColor="#FF9966"
											starEmptyColor="grey"
										/>
									</div>
									<small className="mt-1">{data.date}</small>
								</blockquote>
							);
						})}
					</div>

					<footer>
						<p>
							Read all on
							<a
								target="_blank"
								href="https://www.reviews.io/company-reviews/store/1800battery.com-0900"
								rel="noreferrer">
								<img src={reviewsIo.src} alt="reviews_logo" />
							</a>
						</p>
					</footer>
				</div>
			)}

			{type === 'carousel' && (
				<div className="reviews carousel">
					<div id="carousel-widget"></div>
				</div>
			)}

			{type === 'dropdown' && (
				<a
					className="reviews dropdown"
					href="https://www.reviews.io/company-reviews/store/1800battery.com-0900"
					target="_blank"
					rel="noreferrer">
					<StarRatings
						numberOfStars={5}
						rating={4.5}
						starDimension="17px"
						starSpacing="0"
						starRatedColor="#FF9966"
						starEmptyColor="grey"
					/>
					<ul>
						<li>
							<span>{average_rating}</span> Avg.
						</li>
						<li>
							<span>{total_reviews}</span> Reviews
						</li>
					</ul>
				</a>
			)}

			{type === 'textBanner' && (
				<div className="reviews text-banner">
					<div className="intro">
						<div className="logo">
							<Image width={50} height={50} src={reviewsIoSquare} alt="reviews_logo" />
						</div>

						<div className="text">
							<span className="p">
								Our customers say <span>Excellent </span>
								<StarRatings
									numberOfStars={5}
									rating={4.5}
									starDimension="16px"
									starSpacing="0"
									starRatedColor="#FF9966"
									starEmptyColor="grey"
								/>
								<span> ({average_rating})</span> out of 5 based on <span>{total_reviews}</span> reviews
							</span>
						</div>
					</div>
				</div>
			)}

			{type === 'minimal' && (
				<div className="text-sm bg-slate-100 inline-block rounded-md !p-2 !px-3">
					<span className="p">
						Our customers say <b>Excellent </b>
						<StarRatings
							numberOfStars={5}
							rating={4.8}
							starDimension="16px"
							starSpacing="0"
							starRatedColor="#FF9966"
							starEmptyColor="grey"
						/>
						{' '}based on <b>{total_reviews}</b> reviews
					</span>
				</div>
			)}

			{type === 'static' && (
				<div className="reviews static">
					<h3>We Come to Your Location and Provide Our Expertise with Stellar Results</h3>
					<div className="intro">
						<span className="p">
							Our customers say <span>Excellent </span>
							<StarRatings
								numberOfStars={5}
								rating={4.5}
								starDimension="23px"
								starSpacing="0"
								starRatedColor="#FF9966"
								starEmptyColor="grey"
							/>
							<span> ({average_rating})</span> out of 5 based on <span>{total_reviews}</span> reviews
						</span>
					</div>

					<div className="review-items">
						<div className="review-item">
							<blockquote>
								Really phenomenal from start to finish. Customer service was great in the beginning
								helping with questions and scheduling. Our installer was a great guy and quick.
							</blockquote>
							<small>Tom</small>
						</div>

						<div className="review-item">
							<blockquote>
								From the time I called to completion was about an hour. Anywhere else, I would have
								waited at least an hour before work started. And I never had to leave home!
							</blockquote>
							<small>William S</small>
						</div>

						<div className="review-item">
							<blockquote>
								{`Incredibly convenient, courteous, and competent. Hoping I won't have a car battery die unexpectedly on me again...but if it does happen, will definitely use this service.`}
							</blockquote>
							<small>Ben B</small>
						</div>
					</div>
					<div className="view-more">
						<p>
							Read all on
							<a
								className="position-relative"
								target="_blank"
								href="https://www.reviews.io/company-reviews/store/1800battery.com-0900"
								rel="noreferrer">
								<Image width="128" height="20" objectFit="contain" src={reviewsIo} alt="reviews_logo" />
							</a>
						</p>
					</div>
				</div>
			)}

			{type === 'static-v2' && (
				<section
				id="reviews"
				aria-labelledby="reviews-title"
				className="pb-10 pt-10 sm:pb-24 sm:pt-20"
			  >
				<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
				  Customer stories
				</h2>
				<p className="mt-6 text-xl leading-8 text-gray-900">
				  Our customers say{" "}
				  <b className="border-r border-gray-400 pr-2 mr-2">Excellent</b>
				  <span className="inline-flex">
						  <svg
							viewBox="0 0 20 20"
							aria-hidden="true"
							className="h-5 w-5 fill-orange-500"
						  >
							<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
						  </svg>
						  <svg
							viewBox="0 0 20 20"
							aria-hidden="true"
							className="h-5 w-5 fill-orange-500"
						  >
							<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
						  </svg>
						  <svg
							viewBox="0 0 20 20"
							aria-hidden="true"
							className="h-5 w-5 fill-orange-500"
						  >
							<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
						  </svg>
						  <svg
							viewBox="0 0 20 20"
							aria-hidden="true"
							className="h-5 w-5 fill-orange-500"
						  >
							<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
						  </svg>
						  <svg
							viewBox="0 0 20 20"
							aria-hidden="true"
							className="h-5 w-5 fill-orange-500"
						  >
							<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
						  </svg>
						</span>{" "}
				  {/* */}based on <b className="inline-block">4195</b> reviews
				</p>
				<div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none relative">
				  <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3 ">
					{static_reviews_v2.map((review, index) => (
						<div className="pt-4 sm:inline-block sm:w-full sm:px-2" key={index}>
							<figure className="rounded-md bg-gray-50 p-4 text-sm leading-6">
							<span className="flex">
								<svg
								viewBox="0 0 20 20"
								aria-hidden="true"
								className="h-5 w-5 fill-orange-500"
								>
								<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
								</svg>
								<svg
								viewBox="0 0 20 20"
								aria-hidden="true"
								className="h-5 w-5 fill-orange-500"
								>
								<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
								</svg>
								<svg
								viewBox="0 0 20 20"
								aria-hidden="true"
								className="h-5 w-5 fill-orange-500"
								>
								<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
								</svg>
								<svg
								viewBox="0 0 20 20"
								aria-hidden="true"
								className="h-5 w-5 fill-orange-500"
								>
								<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
								</svg>
								<svg
								viewBox="0 0 20 20"
								aria-hidden="true"
								className="h-5 w-5 fill-orange-500"
								>
								<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
								</svg>
							</span>
							<blockquote className="text-gray-900 text-base mt-3 border-none !p-0">
								<p>
								{review.comment}
								</p>
							</blockquote>
							<figcaption className="mt-6 flex items-center gap-x-4">
								<div>
								<div className="font-semibold text-gray-900">{review.author}</div>
								{/* <div className="text-gray-600">1 week ago</div> */}
								</div>
							</figcaption>
							</figure>
						</div>
					))}

				  </div>
				  <div className="absolute w-full h-[500px] bottom-0 left-0 bg-gradient-to-t from-[#F3F3F3] flex items-end justify-center">
					<div className="bg-white  lg:w-1/3 border-2 border-blue-100 shadow-xl p-5 rounded-xl text-center">
					  <h6 className="font-bold tracking-tight text-gray-900 sm:text-2xl">
						Join the millions of happy customers.
					  </h6>
					  <Link href={href || '/'}>
						<a
							className="w-[300px] max-w-[100%] bg-[#4c942f] mx-auto flex flex-1 items-center justify-center rounded-sm !py-3 !px-3 md:px-10 text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 sm:full bg-brand-primary hover:bg-brand-primary/90 text-base font-semibold"

						>
							GET STARTED
						</a>
					  </Link>

					  
					</div>
				  </div>
				</div>
			  </section>
			  
			  
			)}
		</>
	);
}

export default Reviews;
const static_reviews = [
	{
		branch: null,
		comments:
			'I talked to Chris about my sons car battery and he had someone out to my\r\nhome within the hour! More expensive \r\nthan other quotes I got but, for me the convince of have a Tech coming out to\r\nmy house was well worth it. I forgot the Tech&rsquo;s name but he was very nice and quickly changed the battery.',
		date: '7 hours ago',
		date_created: '2021-07-07 03:26:19',
		images: [],
		rating: 5,
		ratings: [],
		reviewer: { first_name: 'Lana', last_name: 'R', verified_buyer: 'yes', address: 'Fort Collins, United States' },
		store_branch_id: null,
		store_review_id: 12470408,
		title: '',
		user_id: 17300814,
	},

	{
		branch: null,
		comments:
			'Guy was friendly and quick. He replaced my old battery and my car started right up. Took about 15-20 min.',
		date: '14 hours ago',
		date_created: '2021-07-06 20:51:11',
		images: [],
		rating: 5,
		ratings: [],
		reviewer: { first_name: 'Chris', last_name: 'M', verified_buyer: 'yes', address: 'Bentonville, United States' },
		store_branch_id: null,
		store_review_id: 12469143,
		title: '',
		user_id: 17299080,
	},

	{
		branch: null,
		comments:
			'My technician, Rossalyn, was prompt, professional, friendly, and highly skilled. She had both raw technical skill and empathy, and really made me see the true value in this service. I would 100% recommend her and 1-800-Battery to anyone in need as an alternative to towing your car or even any similar service. Five stars, two thumbs way up!',
		date: '2 days ago',
		date_created: '2021-07-05 03:31:38',
		images: [],
		rating: 5,
		ratings: [],
		reviewer: { first_name: 'Kevin', last_name: 'S', verified_buyer: 'yes', address: '' },
		store_branch_id: 0,
		store_review_id: 12452075,
		title: '',
		user_id: 17274147,
	},
];


const static_reviews_v2 = [
	{
		comment: 'Impressive! Quick, easy, and no more expensive than taking it to the shop. I\'m a fan!',
		author: 'Aaron R.',
		rating: 5,
	},

	{
		comment: 'I used 1800Battery.com for the first time.  I needed to get a battery but I am disabled and not able to pick up one or get someone else to do it.  So searching, I found them.  I called at first to get some info.  The agent was very helpful.  A few days later I scheduled a time for them to come and install a new battery. The tech, Gerald, came and did such a wonderful job and had a great interaction.  Very pleasant! I drove my car right after that for 40 min. She ran great!  Thanks for great service!!',
		author: 'Velina J.',
		rating: 5,
	},

	{
		comment: 'Excellent, fast service right from my original phone call through to delivery and installation of my new, hard to find, battery.  Gentleman who installed it was friendly, courteous, knowledgeable, and efficient; a pleasure to deal with.  The whole thing took just a few hours. My only complaint is that the total charge was rather high, but what choice does one have when both AAA and my car dealer says it would take weeks to obtain the out of stock battery I need.',
		author: 'Tobin J.',
		rating: 4,
	},

	{
		comment: 'Very satisfied with the technician that was sent-he really knew his stuff-I would rate him a 10 plus!!!',
		author: 'Marie A. D.',
		rating: 5,
	},

	{
		comment: 'I was happy that I got same day service and I did not have to wait long. My tech Matthewe was great and he did an excellent job. I would definitely recommend to anybody who can use the service. Thank you.',
		author: 'Yaritza R.',
		rating: 5,
	},

	{
		comment: 'We came out to the garage to find our battery did not want to start. Found 1-800-battery n scheduled an appointment. At 6pm this gentleman called to say he was here. Ten minutes later our auto was going strong. They kept us informed all the way. Love these guys. Thank you',
		author: 'Robert B.',
		rating: 5,
	},

	{
		comment: 'Impressive! Quick, easy, and no more expensive than taking it to the shop. I\'m a fan!',
		author: 'Aaron R.',
		rating: 5,
	},

	{
		comment: 'Got us out of a bind!  Battery DIED Saturday night. Went to 1-800 Battery.com...and was back up and running with brand new battery by 1:00 pm Sunday. The technician Brandon was awesome. ',
		author: 'Gary C.',
		rating: 5,
	},

	{
		comment: 'I needed a place that I could buy the battery and have it installed.  Most would only just sell you the battery but not do the install.  These guys were great.  Bought the battery online, made an appointment and the technician came during the time he was supposed to.  Pleasant, professional, clean and knew what he was doing.  I couldn\'t be happier.  It was a bit more expensive although you get what you pay for.  Everything was easy and I now have peace of mind.  I would definitely recommend them.',
		author: 'Joanne D.',
		rating: 5,
	},

	{
		comment: 'I give it a 5 star rating.  It was a Saturday. I called up. made a quick appointment. They came out and replaced my battery and I was good to go.  I was out of town so this saved me the trouble of having to find a tow truck, battery, and installer to get this done.  Thank you 1800battery.com!',
		author: 'Zachary B.',
		rating: 5,
	},

	{
		comment: 'Impressive! Quick, easy, and no more expensive than taking it to the shop. I\'m a fan!',
		author: 'Aaron R.',
		rating: 5,
	},

	{
		comment: 'It was a pleasure doing business with 1-800-battery. I was able to easily order a new battery for my 2019 Audi A-4 and arranged installation 3 days later. The technician arrived on time ,tested the existing battery and noted an electrical malfunction not the battery. He recommended towing the car to the dealer and have it checked I was charged for a service call and the new battery was returned. I would highly recommend this company to anyone who needs a new battery ',
		author: 'Joseph C.',
		rating: 5,
	},
];