import * as types from '../constants/actiontypes';

const initialState = {
	category: null,
	breadcrumbs: [],
	childCategories: [],
	featuredCategories: [],
	products: [],
	CurrentIndex: 0,
	application: [],
	applicationLabel: '',
	currentLevelQuery: '',
	vin: '',
	services: [],
	fitmentOptions: [],
	fitmentTarget: {},
};

const Category = (state = initialState, action) => {
	switch (action.type) {
		case types.RESET_CATEGORY:
			return {
				...state,
				category: null,
				breadcrumbs: [],
				childCategories: [],
				featuredCategories: [],
				products: [],
				CurrentIndex: 0,
				application: [],
				applicationLabel: '',
				currentLevelQuery: '',
				vin: '',
				services: [],
			};

		case types.GET_CATEGORY:
			return {
				...state,
				category: action.data.category,
				breadcrumbs: action.data.breadcrumbs,
				childCategories: action.data.childCategories,
				featuredCategories: action.data.featuredCategories,
				products: action.data.products,
				CurrentIndex: action.data.CurrentIndex,
				application: action.data.application,
				applicationLabel: action.data.applicationLabel,
				currentLevelQuery: action.data.currentLevelQuery,
				services: action.data.services,
			};

		case types.SORT_PRODUCT_COLLECTION: {
			return {
				...state,
				products: action.data.products,
			};
		}

		case types.ADD_FITMENT_OPTIONS: {
			// Copy the array content but remove everything after active selection array
			// Helpful for when you reselect Model e.g. and want year options and engine type to reset too
			const newArray = [...state.fitmentOptions].slice(0, action.data.selectionLevel);
			newArray[action.data.selectionLevel] = action.data.data;

			return {
				...state,
				fitmentOptions: newArray,
			};
		}

		case types.ADD_FITMENT_TARGET: {
			return {
				...state,
				fitmentTarget: action.data,
			};
		}

		case types.SET_VIN:
			return {
				...state,
				vin: action.data.vin,
			};
		default:
			return state;
	}
};
export default Category;
