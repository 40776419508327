import Link from 'next/link';
import React, { Component } from 'react';
import { Image } from 'react-img-webp';

class CategoryItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
			image: '',
		};
	}

	onClickHandle(img) {
		this.setState({ image: img });
	}

	render() {
		const { category, isManual } = this.props;
		let imgurl = '/assets/images/dummy.png';

		if (category.imageUrl) {
			// imgurl = category.imageUrl;
			const imageName = category.imageUrl.split('/').pop();
			const ext = imageName.split('.')[1];
			imgurl = `/assets/images/category/${imageName.split('.')[0]}.${ext === 'jpg' ? 'jpeg' : ext}`;
		}

		// var webP = null;
		// if (category.imageUrl) {
		// 	var pos = category.imageUrl.lastIndexOf('.');
		// 	webP = category.imageUrl.substr(0, pos < 0 ? category.imageUrl.length : pos) + '.webp';
		// }

		const url = category.urlSlug ? category.urlSlug : category.id;

		const myHref = isManual ? `/${url}` : `/category/[categoryid]?categoryid=${url}}`;
		const asVar = isManual ? url : `/category/${url}`;

		return (
			<Link href={myHref} as={asVar} prefetch={false}>
				<a className="inner">
					<div className="image-container">
						{/* TODO: Check for Bug - IsManual should just deal with URL Redirect... */}
						{isManual ? (
							<i className="fad fa-layer-group"></i>
						) : (
							<Image
								width="100px"
								height="100px"
								alt="category"
								src={imgurl}
								webP={imgurl}
								layout="intrinsic"
								blur="true"
							/>
						)}
					</div>

					<div className="text-container">
						<p>{category.name}</p>
					</div>
				</a>
			</Link>
		);
	}
}

export default CategoryItem;
