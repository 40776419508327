import axios from 'axios';
import * as rax from 'retry-axios';

import * as types from '../constants/actiontypes';
import config from './config';

const apiPath = config.apiUrl;
const interceptorId = rax.attach();

// export const getAllServices = ID => {
// 	return async (dispatch, getState) => {
// 		const { masterProvider, services } = await getState();
// 		if (services.services.length > 0) {
// 			return;
// 		}
// 		var path = `${apiPath}/services`;

// 		if (masterProvider && masterProvider.isActive) {
// 			path = path + `?providerid=${masterProvider.id}`;
// 		}
// 		return await axios
// 			.get(path, { crossdomain: true })
// 			.then(response => {
// 				console.log('SERVICES', response);
// 				dispatch({
// 					type: types.RECEIVE_SERVICES,
// 					data: response.data,
// 				});
// 			})
// 			.catch(error => {
// 				throw error;
// 			});
// 	};
// };

export const getAllServices = async () => {
	var path = `${apiPath}/services`;

	const { data } = await axios.get(path, { crossdomain: true });
	return data;
};
