import axios from 'axios';

import * as types from '../constants/actiontypes';
import config from './config';

const apiPath = config.apiUrl;

// export const getAllBrands = ID => {
// 	return async (dispatch, getState) => {
// 		const { masterProvider, brands } = await getState();
// 		if (brands.brands.length > 0) {
// 			return;
// 		}

// 		var path = `${apiPath}/brand`;

// 		if (masterProvider && masterProvider.isActive) {
// 			path = path + `?providerid=${masterProvider.id}`;
// 		}

// 		return await axios
// 			.get(path, { crossdomain: true })
// 			.then(response => {
// 				dispatch({
// 					type: types.RECEIVE_BRANDS,
// 					data: response.data,
// 				});
// 			})
// 			.catch(error => {
// 				throw error;
// 			});
// 	};
// };

export const getAllBrands = async masterProvider => {
	var path = `${apiPath}/brand`;
	if (masterProvider && masterProvider.isActive) {
		path = path + `?providerid=${masterProvider.id}`;
	}
	const { data } = await axios.get(path, { crossdomain: true });
	return data;
};
