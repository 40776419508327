export const SET_PROVIDER = 'provider/SET_PROVIDER';
export const RESET_PROVIDER = 'provider/RESET_PROVIDER';

const setProvider = provider => ({
	type: SET_PROVIDER,
	payload: {
		provider,
	},
});

const resetProvider = () => ({
	type: RESET_PROVIDER,
});

export const resetSelectedProvider = () => async dispatch => {
	dispatch(resetProvider());
};

export const setSelectedProvider = provider => async dispatch => {
	dispatch(setProvider(provider));
};
