import * as types from '../constants/actiontypes';

const initialState = {
	allCategories: [],
};

const Categories = (state = initialState, action) => {
	switch (action.type) {
		case types.RECEIVE_CATEGORIES:
			return { ...state, allCategories: action.data };

		default:
			return state;
	}
};
export default Categories;
