import { SET_REVIEWS } from '../actions/reviews';

const initialState = {
	reviews: null,
};

export default function reviewsReducer(state = initialState, action) {
	switch (action.type) {
		case SET_REVIEWS:
			return action.payload.reviews;
		default:
			return state;
	}
}
