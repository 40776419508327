import { useEffect } from 'react';
import GBTracker from 'gb-tracker-client';

export const GroupByBeacons = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.gbTracker = new GBTracker('1800battery', 'Production');
            window.gbTracker.autoSetVisitor();
        }
    }, []);

    return null;
};