import { GET_CHECKOUT, RESET_CHECKOUT, UPDATE_CHECKOUT_STEP } from '../actions/checkout';

const initialState = {
	checkout: null,
	upToDateStep: 0,
};

export default function checkoutReducer(state = initialState, action) {
	switch (action.type) {
		case UPDATE_CHECKOUT_STEP:
			// console.log('step updated for real', action.payload)
			return { ...state, upToDateStep: action.payload.step };

		case GET_CHECKOUT:
			return { ...state, checkout: action.data };

		case RESET_CHECKOUT:
			return { ...state, checkout: null };

		default:
			return state;
	}
}
