export const SET_GEOLOCATION = 'geolocation/SET_GEOLOCATION';
export const RESET_GEOLOCATION = 'geolocation/RESET_GEOLOCATION';
export const SET_GEOLOCATION_LOADING = 'geolocation/SET_GEOLOCATION_LOADING';

import { removeLocation } from '../../utils/geolocation';
import { setLocation as updateLocation } from '../../utils/geolocation';

const setGeolocation = geolocation => ({
	type: SET_GEOLOCATION,
	payload: {
		geolocation,
	},
});

const setGeolocationLoading = state => ({
	type: SET_GEOLOCATION_LOADING,
	payload: state,
});

const resetGeolocation = () => ({
	type: RESET_GEOLOCATION,
});

export const setLocation = geolocation => async dispatch => {
	// TODO: Get Current geolcation and compare to avoid uncessary writes to storage and dispatch...
	updateLocation(geolocation);
	await dispatch(setGeolocation(geolocation));
};

export const setLocationLoading = state => async dispatch => {
	await dispatch(setGeolocationLoading(state));
};

export const resetLocation = geolocation => async dispatch => {
	removeLocation();
	dispatch(resetGeolocation(geolocation));
};
