import Script from 'next/script';

export const TermlyInit = () => {
	return (
		<Script
			strategy="afterInteractive"
			id="termly-blocker-script"
			type="text/javascript"
			src="https://app.termly.io/resource-blocker/412d3822-acda-4f56-8861-c6eba6a6f1e3?autoBlock=off"
		></Script>
	);
};
