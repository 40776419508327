import Router from 'next/router';

import { getCustomer } from '../../bigcommerce/api/customers/get-customer';
import { removeCustomer } from '../../utils/customer';
export const LOGIN_INIT = 'customer/LOGIN_INIT';
export const LOGIN_SUCCESS = 'customer/LOGIN_SUCCESS';
export const LOGOUT = 'customer/LOGOUT';
export const UPDATE_DURING_CHECKOUT = 'customer/UPDATE_DURING_CHECKOUT';
import { UpdateCartCustomer } from '../../bigcommerce/api/cart/get-cart';
import { addCustomerIdToCart } from '.';

const loginInit = (username, password) => ({
	type: LOGIN_INIT,
	payload: { username, password },
});

const loginSuccess = customer => ({
	type: LOGIN_SUCCESS,
	payload: {
		customer,
	},
});

const logoutSuccess = () => ({
	type: LOGOUT,
});

export const login =
	({ username, password, redirectUrl, cartId }) =>
	async (dispatch, getState) => {
		dispatch(loginInit(username, password));

		const customer = await getCustomer({
			username,
			password,
		});

		dispatch(loginSuccess(customer));
		if (cartId) {
			const bigCommerce = await UpdateCartCustomer(cartId, customer.id);
			dispatch(addCustomerIdToCart(bigCommerce));
		}

		const { navigation } = getState();
		console.log('ROUTER', redirectUrl, navigation);
		var redirect = redirectUrl ? redirectUrl : navigation.previousUrl;
		if (redirect && redirect != 'none') {
			Router.push(redirect).then(() => window.scrollTo(0, 0)); // TODO: Will have to be "redirect URL from where page originated from"
		}
		return customer;
	};

export const logout = redirectUrl => dispatch => {
	removeCustomer();

	if (redirectUrl) {
		Router.push(redirectUrl);
	}

	dispatch(logoutSuccess());
};
export const updateCheckoutCustomer = customer => dispatch => {
	// TODO: If Update Password >> Dispatch Init
	dispatch(loginSuccess(customer));
};
