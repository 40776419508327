import Image from 'next/image';
import { FC } from 'react';

const features = [
	{
		name: 'Professional installation included in price',
		description: 'No Hidden Charges!',
		iconSrc: '/images/why-us/professional-installation.svg',
	},

	{
		name: 'Fitment guaranteed',
		description: 'Buy with confidence knowing that your new car battery will fit perfectly',
		iconSrc: '/images/why-us/fitment.svg',
	},

	{
		name: 'We come to you',
		description:
			"Whether you're on the road or relaxing at home, we bring our services directly to you, ensuring hassle-free battery replacements.",
		iconSrc: '/images/why-us/we-come-to-you.svg',
	},

	{
		name: 'Geolocation for fast and efficient service',
		description:
			'Through the geolocation-based operations, we automatically locate the nearest shop and assign an available technician to handle your battery replacement.',
		iconSrc: '/images/why-us/geolocation.svg',
	},
	{
		name: 'Make an appointment',
		description:
			'You have the flexibility to choose the appointment time that aligns with your schedule and the location that is most convenient for you.',
		iconSrc: '/images/why-us/make-an-appoitment.svg',
	},
	{
		name: 'Track your order in real time',
		description:
			'Track the progress of your order, including the real-time location of our technician and an accurate ETA.',
		iconSrc: '/images/why-us/track-your-delivery.svg',
	},

	{
		name: 'Buy now, pay over time',
		description: 'Make your purchase now and spread out the payments over a period of time. Powered by Affirm.',
		iconSrc: '/images/why-us/affirm.svg',
	},

	{
		name: 'Customer approved',
		description: 'Our customers say Excellent based on 4000+ reviews',
		iconSrc: '/images/why-us/customer-approved.svg',
	},
];

const WhyFeatures = ({ title, hideDescription }) => {
	return (
		<div>
			{title ? (
				<h2 className="mt-2 text-2xl font-semibold tracking-tight text-gray-800 sm:text-4xl">
					{title}
				</h2>
			) : (
				<div className="max-w-2xl">
					<p className="mt-2 text-2xl font-semibold tracking-tight text-gray-800 sm:text-3xl">
						We are the largest Battery Marketplace in North America. Over 10,000 products, 250,000
						locations, and 1,000,000 needs served.
					</p>
					<p className="text-xl !mt-4">From Cars to Coin Cells we have everything available.</p>
				</div>
			)}

			<div className="mx-auto mt-16 sm:mt-20 lg:mt-24">
				<dl className="grid grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
					{features.map(feature => (
						<div key={feature.name} className="relative pl-16">
							<dt className="text-base font-semibold leading-7 text-gray-900">
								<div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-full bg-gray-100">
									{/* <FontAwesomeIcon size="2xl" className="text-green-700" icon={faCheckCircle} /> */}
									<Image src={feature.iconSrc} width={64} height={64} alt={feature.name} />
								</div>
								<span className="text-xl">{feature.name}</span>
							</dt>
							<dd className="mt-2 text-base leading-7 text-gray-700">{feature.description}</dd>
						</div>
					))}
				</dl>
			</div>
		</div>
	);
};

export default WhyFeatures;
