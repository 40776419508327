import { RESET_PROVIDER, SET_PROVIDER } from '../actions/provider';

const initialState = {
	serviceId: null,
	providerId: null,
};

export default function providerReducer(state = initialState, action) {
	switch (action.type) {
		case SET_PROVIDER:
			return action.payload.provider;
		case RESET_PROVIDER:
			return initialState;
		default:
			return state;
	}
}
