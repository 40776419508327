import Script from 'next/script';
import React from 'react';

export const KeynesUniversalPixel = () => (
	<>
		<Script
			src="https://js.adsrvr.org/up_loader.1.1.0.js"
			onLoad={() => {
				ttd_dom_ready(function () {
					if (typeof TTDUniversalPixelApi === 'function') {
						var universalPixelApi = new TTDUniversalPixelApi();
						universalPixelApi.init('b43zpat', ['5crllde'], 'https://insight.adsrvr.org/track/up');
					}
				});
			}}></Script>
	</>
);

export const KeynesLALSiteVisitPixel = () => (
	<>
		<Script src="//action.dstillery.com/orbserv/nsjs?adv=cl164393222295953&ns=6714&nc=1-800-battery-site-visit&ncv=51&"></Script>
		<noscript>
			<img
				width="1"
				height="1"
				src="//action.dstillery.com/orbserv/nspix?adv=cl164393222295953&ns=6714&nc=1-800-battery-site-visit&ncv=51"
			/>
		</noscript>
	</>
);

export const KeynesConversionPixel = ({ orderid, salesAmount, currency }) => (
	<img
		height="1"
		width="1"
		style={{ borderStyle: 'none' }}
		alt=""
		src={`https://insight.adsrvr.org/track/pxl/?adv=b43zpat&ct=0:282hybw&fmt=3&orderid=${orderid}&v=${salesAmount}&vf=${currency}`}
	/>
);

export const KeynesLALConversionPixel = () => (
	<>
		<Script src="//action.dstillery.com/orbserv/nsjs?adv=cl164393222295953&ns=6714&nc=1-800-battery-conversions&ncv=51"></Script>
		<noscript>
			<img
				width="1"
				height="1"
				src="//action.dstillery.com/orbserv/nspix?adv=cl164393222295953&ns=6714&nc=1-800-battery-conversions&ncv=51"
			/>
		</noscript>
	</>
);