import {
	ADD_CUSTOMER_ID,
	ADD_TO_CART,
	CLEAR_CART,
	DECREMENT_QTY,
	REMOVE_FROM_CART,
	UPDATE_CHECKOUT_STEP,
	UPDATE_QTY,
	UPDATE_SELECTED_PROVIDER,
} from '../constants/actiontypes';

const initialState = {
	cart: [],
	bigCommerceCart: null,
	cartStep: 0,
};

export default function cartReducer(state = initialState, action) {
	switch (action.type) {
		case ADD_TO_CART: {
			const productId = action.product.id;

			//
			if (state.cart.findIndex(product => product.id === productId) !== -1) {
				const cart = state.cart.reduce((cartAcc, product) => {
					if (product.id === productId) {
						cartAcc.push({
							...product,
							qty: product.qty + action.qty,
							sum: product.price * (product.qty + action.qty),
						}); // Increment qty
					} else {
						cartAcc.push(product);
					}

					return cartAcc;
				}, []);

				return {
					...state,
					cart: cart,
					bigCommerceCart: action.bigCommerceCart,
					cartStep: 0,
				};
			}

			return {
				...state,

				cart: [
					...state.cart,
					{
						...action.product,
						qty: action.qty,
						sum: action.product.price * action.qty,
					},
				],
				bigCommerceCart: action.bigCommerceCart,
				cartStep: 0,
			};
		}
		case DECREMENT_QTY:
			if (state.cart.findIndex(product => product.id === action.productId) !== -1) {
				const cart = state.cart.reduce((cartAcc, product) => {
					if (product.id === action.productId && product.qty > 1) {
						cartAcc.push({
							...product,
							qty: product.qty - 1,
							sum: product.price * (product.qty - 1),
						}); // Decrement qty
					} else {
						cartAcc.push(product);
					}

					return cartAcc;
				}, []);

				return {
					...state,
					cart,
					bigCommerceCart: action.bigCommerceCart,
					cartStep: 0,
				};
			}
			break;

		case UPDATE_QTY: {
			if (state.cart.findIndex(product => product.id === action.productId) !== -1) {
				const cart = state.cart.reduce((cartAcc, product) => {
					if (product.id === action.productId && product.qty > 1) {
						cartAcc.push({
							...product,
							qty: action.qty,
							sum: product.price * action.qty,
						}); // Decrement qty
					} else {
						cartAcc.push(product);
					}

					return cartAcc;
				}, []);

				return {
					...state,
					cart,
					bigCommerceCart: action.bigCommerceCart,
					cartStep: 0,
				};
			}

			return {
				...state,
				cart: [
					...state.cart,
					{
						...action.product,
						qty: action.qty,
						sum: action.product.price * action.qty,
					},
				],
			};
		}

		case REMOVE_FROM_CART:
			var bigCommerce = action.bigCommerceCart ? action.bigCommerceCart : null;

			return {
				cart: state.cart.filter(x => x.id !== action.productId),
				bigCommerceCart: bigCommerce,
				cartStep: 0,
			};
		case ADD_CUSTOMER_ID: {
			// FIXME: duplicate definition of `bigCommerce` (V.T. 22.12.2021.)
			var bigCommerceA = action.payload.bigCommerceCart ? action.payload.bigCommerceCart : null;
			return {
				...state,
				bigCommerceCart: bigCommerceA,
				cartStep: 0,
			};
		}
		case UPDATE_CHECKOUT_STEP:
			return {
				...state,
				cartStep: action.payload.cartStep,
			};

		case UPDATE_SELECTED_PROVIDER:
			return {
				...state,
				cart: action.cart.cart,
			};

		case CLEAR_CART:
			return initialState;
		default:
	}
	return state;
}
