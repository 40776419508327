import axios from 'axios';
import * as rax from 'retry-axios';

import * as types from '../constants/actiontypes';
import config from './config';
import { SET_PROVIDER } from './provider';

const apiPath = config.apiUrl;
const interceptorId = rax.attach();

import { getChildCategoryLoadOnly } from '../../functions/getChildCategoryLoadOnly';

// export const getAllCategories = ID => {
// 	return async (dispatch, getState) => {
// 		const { masterProvider, categories } = await getState();

// 		if (categories.allCategories.length > 0) {
// 			return;
// 		}
// 		var path = `${apiPath}/category`;

// 		if (masterProvider && masterProvider.isActive) {
// 			path = path + `?providerid=${masterProvider.id}`;
// 		}
// 		return await axios
// 			.get(path, { crossdomain: true })
// 			.then(response => {
// 				dispatch({
// 					type: types.RECEIVE_CATEGORIES,
// 					data: response.data,
// 				});
// 			})
// 			.catch(error => {
// 				throw error;
// 			});
// 	};
// };

export const getAllCategories = async masterProvider => {
	var path = `${apiPath}/category`;

	if (masterProvider && masterProvider.isActive) {
		path = path + `?providerid=${masterProvider.id}`;
	}

	const { data } = await axios.get(path, { crossdomain: true });

	return data;
};

export const resetCategory = () => {
	return async dispatch => {
		dispatch({
			type: types.RESET_CATEGORY,
		});
	};
};

export const setVin = vin => {
	return async dispatch => {
		dispatch({
			type: types.SET_VIN,
			data: { vin: vin },
		});
	};
};

export const sortProductCollection = (products, servicearray, productLevelType) => {
	return async dispatch => {
		var resortProducts = [];
		var appendUnselected = [];
		products.forEach(product => {
			var item = JSON.parse(JSON.stringify(product));
			if (item.groupMessage && productLevelType && productLevelType == item.groupMessage) {
				item.isRecommended = true;
			} else {
				item.isRecommended = false;
			}

			if (item.deliveryLocations.length > 0) {
				if (servicearray.Services.some(service => service.id == item.deliveryLocations[0].ServiceId)) {
					item.selectedProviderService.providerId = item.deliveryLocations[0];
					item.selectedProviderService.serviceId = item.deliveryLocations[0].ServiceId;

					dispatch({
						type: SET_PROVIDER,
						payload: {
							provider: {
								providerId: item.selectedProviderService.providerId.ProviderId,
								serviceId: item.deliveryLocations[0].ServiceId,
							},
						},
					});

					resortProducts.push(item);
					return;
				}
			}

			if (item.installLocations.length > 0) {
				if (servicearray.Services.some(service => service.id == item.installLocations[0].ServiceId)) {
					item.selectedProviderService.providerId = item.installLocations[0];
					item.selectedProviderService.serviceId = item.installLocations[0].ServiceId;
					dispatch({
						type: SET_PROVIDER,
						payload: {
							provider: {
								providerId: item.selectedProviderService.providerId.ProviderId,
								serviceId: item.installLocations[0].ServiceId,
							},
						},
					});

					resortProducts.push(item);

					return;
				}
			}

			if (item.pickupLocations.length > 0) {
				if (servicearray.Services.some(service => service.id == item.pickupLocations[0].ServiceId)) {
					item.selectedProviderService.providerId = item.pickupLocations[0];
					item.selectedProviderService.serviceId = item.pickupLocations[0].ServiceId;

					dispatch({
						type: SET_PROVIDER,
						payload: {
							provider: {
								providerId: item.selectedProviderService.providerId.ProviderId,
								serviceId: item.pickupLocations[0].ServiceId,
							},
						},
					});

					// Dispatch set provider...

					resortProducts.push(item);

					return;
				}
			}

			if (item.repairLocations.length > 0) {
				if (servicearray.Services.some(service => service.id == item.repairLocations[0].ServiceId)) {
					item.selectedProviderService.providerId = item.repairLocations[0];
					item.selectedProviderService.serviceId = item.repairLocations[0].ServiceId;
					dispatch({
						type: SET_PROVIDER,
						payload: {
							provider: {
								providerId: item.selectedProviderService.providerId.ProviderId,
								serviceId: item.repairLocations[0].ServiceId,
							},
						},
					});

					resortProducts.push(item);
					return;
				}
			}

			if (item.roadsideLocations.length > 0) {
				if (servicearray.Services.some(service => service.id == item.roadsideLocations[0].ServiceId)) {
					item.selectedProviderService.providerId = item.roadsideLocations[0];
					item.selectedProviderService.serviceId = item.roadsideLocations[0].ServiceId;
					dispatch({
						type: SET_PROVIDER,
						payload: {
							provider: {
								providerId: item.selectedProviderService.providerId.ProviderId,
								serviceId: item.roadsideLocations[0].ServiceId,
							},
						},
					});

					resortProducts.push(item);
					return;
				}
			}

			if (item.shippingLocations.length > 0) {
				if (servicearray.Services.some(service => service.id == item.shippingLocations[0].ServiceId)) {
					item.selectedProviderService.providerId = item.shippingLocations[0];
					item.selectedProviderService.serviceId = item.shippingLocations[0].ServiceId;
					dispatch({
						type: SET_PROVIDER,
						payload: {
							provider: {
								providerId: item.selectedProviderService.providerId.ProviderId,
								serviceId: item.shippingLocations[0].ServiceId,
							},
						},
					});

					resortProducts.push(item);
					return;
				}
			}

			if (item.eventLocations.length > 0) {
				if (servicearray.Services.some(service => service.id == item.eventLocations[0].ServiceId)) {
					item.selectedProviderService.providerId = item.eventLocations[0];
					item.selectedProviderService.serviceId = item.eventLocations[0].ServiceId;
					dispatch({
						type: SET_PROVIDER,
						payload: {
							provider: {
								providerId: item.selectedProviderService.providerId.ProviderId,
								serviceId: item.eventLocations[0].ServiceId,
							},
						},
					});

					resortProducts.push(item);
					return;
				}
			}

			appendUnselected.push(item);
		});

		if (resortProducts.length == 1) {
			resortProducts[0].isRecommended = true;
		}
		resortProducts = resortProducts.sort(function (a, b) {
			return b.isRecommended - a.isRecommended;
		});

		resortProducts = resortProducts.concat(appendUnselected);

		dispatch({
			type: types.SORT_PRODUCT_COLLECTION,
			data: { products: resortProducts },
		});
	};
};

export const getCategoryProps = categoryid => {
	return async (dispatch, getState) => {
		var { geolocation, masterProvider, category, pricelevel, roadside } = await getState();

		var breadcrumbs = [];
		var childCategories = [];
		var featuredCategories = [];
		var services = [];
		var products = [];
		var CurrentIndex = 0;
		var application = [];
		var applicationLabel = '';
		var currentLevelQuery = '';
		breadcrumbs[0] = { id: 0, name: 'Home' };

		var path = `${apiPath}/crossreference/products/${categoryid}`;
		var xrefPath = `${apiPath}/crossreference/${categoryid}`;

		if (categoryid) {
			var amp = '?';
			if (geolocation.latitude && geolocation.longitude) {
				path = path + `${amp}lat=${geolocation.latitude}&lng=${geolocation.longitude}`;
				amp = '&';
			}

			if (masterProvider && masterProvider.isActive) {
				path = path + `${amp}providerid=${masterProvider.id}`;
				xrefPath = xrefPath + `?providerid=${masterProvider.id}`;
			} else {
				path = path + `${amp}providerid=0`;
				xrefPath = xrefPath + `?providerid=0`;
			}

			if (pricelevel.priceLevel) {
				path = path + `${amp}priceLevel=${pricelevel.priceLevel}`;
				amp = '&';
			}

			if (roadside.request) {
				path = path + `${amp}externalrequestId=${roadside.request.guid}`;
				amp = '&';
			}

			let allCalls = [`${apiPath}/category/${categoryid}`, path, xrefPath];

			var response = null;
			await axios
				.all([
					axios.get(allCalls[0], { crossdomain: true }),
					axios.get(allCalls[1], { crossdomain: true }),
					axios.get(allCalls[2], { crossdomain: true }),
				])
				.then(
					axios.spread(function (cat, productload, xref) {
						category = cat.data[0];
						if (category) {
							breadcrumbs.push({ id: category.id, name: category.name });
						}
						products = productload.data.product;
						services = productload.data.services;
						response = xref;
					})
				)
				.catch(error => {
					// Do Nothing.  It's only here for an empty Category - Can Move Function into Below if Necessary to prevent 500 error.
				});

			if (breadcrumbs.length < childCategories.length) {
				childCategories = [];
			}

			var childArrayIdFactory = [];
			const l = response ? response.data.length : 0;
			if (l > 0) {
				category = response.data[0].category ? response.data[0].category : category;

				var bcc = response.data[0].categoryBreadCrumbText ? response.data[0].categoryBreadCrumbText.length : 0;

				if (bcc > 0) {
					let names = response.data[0].categoryBreadCrumbText.split(',');

					let ids = response.data[0].categoryBreadCrumbIds.split(',');
					let labels = category.dropDownSearchNames.split(',');
					currentLevelQuery = labels ? labels[0] : '';

					if (category.urlSlug == categoryid) {
						breadcrumbs.pop();
						application.pop();
					}

					var y = 0;
					const tempCatIndex = ids.map(e => e).indexOf(response.data[0].category.id.toString());
					const catindex = tempCatIndex !== -1 ? tempCatIndex : 1;
					var idlength =  ids.length ? ids.length  : 0 ;
				 
					 var productslength = products ? products.length ? products.length : 0 : 0;
					 
					var crumbcount = idlength - 1; // FIXME: - TEMP HACK = products can occur at multiple levels...
					if (productslength > 0) {
						crumbcount = idlength ? idlength : 0 ;
					}
					for (let x = 0; x < crumbcount; x++) {
						breadcrumbs.push({ id: ids[x], name: names[x] });
						if (x > catindex && names[x]) {
							var mylabel = labels ? labels[y] : '';
							application.push({
								id: ids[x],
								name: names[x],
								label: mylabel,
							});
							if (labels.length >= x) {
								currentLevelQuery = labels ? labels[y + 1] : '';
							}
							if (names[x]) {
								applicationLabel = applicationLabel + ' ' + names[x];
							}
							y++;
						}
					}

					if (products != 'undefined') {
						for (let x = 0; x < productslength - 1; x++) {
							products[x].application = application;
							products[x].applicationLabel = applicationLabel;
						}
					}

					// PLace Child Data
					var childindex = ids
						.map(e => e)
						.indexOf(response.data[0].rootId ? response.data[0].rootId.toString() : '');
					if (childindex == -1) {
						childindex = breadcrumbs.length - 1;
					}

					var catoffset = childindex - catindex;
					for (var i = breadcrumbs.length - 1; i >= 0; i--) {
						if (i === catoffset && category.id !== 0) {
							CurrentIndex = i;
							childCategories[i] = response.data.filter(item => item.urlSlug != categoryid);

							var isFeatured = response.data.filter(item => item.isFeatured == true);

							featuredCategories[i] = isFeatured;
							if (featuredCategories[i].length == childCategories[i].length) {
								featuredCategories[i] = [];
							}
						} else {
							if (childCategories[i] === undefined && i < childindex && !products == false) {
								const childCatId = ids[i + 1];
								childArrayIdFactory.push(childCatId);
							}
						}
					}
				}

				// If user comes to this page, it means they've overwritten the Fitment component data
				// Act accordingly
				const fullUrlSlug = response.data[0].urlSlug;
				const target = response.data[0].searchValue;
				const categoryUrlSlug = response.data[0].category.urlSlug;
			
				dispatch({
					type: types.ADD_FITMENT_TARGET,
					data: {
						target: target,
						searchValue: fullUrlSlug.split(`${categoryUrlSlug}-`)[1],
						fullUrlSlug: fullUrlSlug,
						verifiedFitment: {},
					},
				});
			}

			if (childArrayIdFactory.length > 0) {
				childCategories = await getCategoryChildren(childArrayIdFactory.reverse(), childCategories);
			}

			if (
				response &&
				response.data &&
				response.data.length > 0 &&
				response.data[0].crossReferenceBatteryGroupFitment
			) {
				category.crossReferenceBatteryGroupFitment = response.data[0].crossReferenceBatteryGroupFitment;
			}
		}

		dispatch({
			type: types.GET_CATEGORY,
			data: {
				categoryid: categoryid,
				isLoadingCategory: false,
				category: category,
				breadcrumbs: breadcrumbs,
				childCategories: childCategories,
				featuredCategories: featuredCategories,
				CurrentIndex: CurrentIndex,
				products: products,
				application: application,
				applicationLabel: applicationLabel,
				currentLevelQuery: currentLevelQuery,
				services: services,
			},
		});
	};
};

export const getFitmentModels = (categoryid, level) => {
	return async (dispatch, getState) => {
		// If Category is Undefined - Dispatch reset to state..
		var breadcrumbs = [];
		breadcrumbs[0] = { id: 0, name: 'Home' };
		// Call Ancestor for root variables   RE-WRAP THIS IN A PROMISE ALL

		var path = `${apiPath}/crossreference/products/${categoryid}`;
		var xrefPath = `${apiPath}/crossreference/${categoryid}`;
		let response = [];
		const lightResults = { selectionLevel: level, data: [] };

		if (categoryid) {
			await axios.get(xrefPath, { crossdomain: true }).then(data => {
				response = data;
			});

			response?.data?.forEach(element => {
				lightResults.data.push({
					label: element.name,
					value: element.name,
					searchValue: element.searchValue,
					urlSlug: element.urlSlug,
					categoryBreadCrumbText: element.categoryBreadCrumbText,
				});
			});
		}

		dispatch({
			type: types.ADD_FITMENT_OPTIONS,
			data: lightResults,
		});
	};
};

export const getFitmentModelsNoStore = async (categoryid, level) => {
	console.log('CAT IDDD', categoryid);
	var path = `${apiPath}/crossreference/products/${categoryid}`;
	var xrefPath = `${apiPath}/crossreference/${categoryid}`;
	let response = [];
	const lightResults = { selectionLevel: level, data: [] };

	if (categoryid) {
		await axios.get(xrefPath, { crossdomain: true }).then(data => {
			response = data;
		});

		console.log('RES', response.data);
		return response.data.forEach(element => {
			lightResults.data.push({
				label: element.name,
				value: element.name,
				searchValue: element.searchValue,
				urlSlug: element.urlSlug,
				categoryBreadCrumbText: element.categoryBreadCrumbText,
			});
		});
	}

	return [];
};

export const setFitmentTarget = data => {
	return async (dispatch, getState) => {
		dispatch({
			type: types.ADD_FITMENT_TARGET,
			data: data,
		});
	};
};

async function getCategoryChildren(childArrayIdFactory, childCategories) {
	await Promise.all(
		childArrayIdFactory.map(async (item, index) => {
			var children = await getChildCategoryLoadOnly(item, childCategories[index]);
			childCategories[index] = children;
		})
	);

	return childCategories;
}
