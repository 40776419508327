export const SET_NAVIGATION = 'navigation/SET_NAVIGATION';
export const RESET_NAVIGATION = 'navigation/RESET_NAVIGATION';

const updateNavigation = navigation => ({
	type: SET_NAVIGATION,
	payload: {
		navigation,
	},
});

const clearNavigation = () => ({
	type: RESET_NAVIGATION,
});

export const setNavigation = navigation => async dispatch => {
	dispatch(updateNavigation(navigation));
};

export const resetNavigation = navigation => async dispatch => {
	dispatch(clearNavigation(navigation));
};
