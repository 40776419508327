import { getLocationProps } from '../../functions/getLocationProps';

export const SET_PROVIDERLOCATION = 'SET_PROVIDERLOCATION';
export const RESET_PROVIDERLOCATION = 'RESET_PROVIDERLOCATION';

const setProviderLocation = providerLocation => ({
	type: SET_PROVIDERLOCATION,
	payload: {
		providerLocation,
	},
});

const resetProviderLocation = () => ({
	type: RESET_PROVIDERLOCATION,
});

export const resetSelectedProviderLocation = () => async dispatch => {
	dispatch(resetProviderLocation());
};

export const setSelectedProviderLocation = (providerLocation, geolocation) => async dispatch => {
	var result = await getLocationProps(providerLocation, geolocation);
	dispatch(setProviderLocation(result));
};
