import { RESET_NAVIGATION, SET_NAVIGATION } from '../actions/navigation';

const initialState = {
	previousUrl: null,
};

export default function geoLocationReducer(state = initialState, action) {
	switch (action.type) {
		case SET_NAVIGATION:
			return action.payload.navigation;
		case RESET_NAVIGATION:
			return initialState;
		default:
			return state;
	}
}
