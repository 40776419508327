import { api } from '../api-client';

export const addCart = async (product, qty, customerId, cartId) => {
	var checkCartId = cartId ? cartId : 0;

	const requestBody = {
		product_id: product.bigCommerceId,
		quantity: qty,
		list_price: product.price,
		customer_id: customerId,
		cart_id: checkCartId,
	};

	const cart = await api('/carts/add', {
		method: 'POST',
		body: JSON.stringify(requestBody),
	});

	const return_code = cart.code ? cart.code : 201;
	if (return_code == 201) {
		return cart;
	} else {
		throw new Error(cart.responseBody);
	}
};

export const UpdateLineItemCart = async (product, qty, cartId, lineId) => {
	const path = `/carts/${cartId}/items/${lineId}`;

	const requestBody = {
		line_item: {
			product_id: product.bigCommerceId,
			quantity: qty,
			list_price: product.price,
		},
	};
	const cart = await api(path, {
		method: 'PUT',
		body: JSON.stringify(requestBody),
	});

	const return_code = cart.code ? cart.code : 201;
	if (return_code == 201) {
		return cart;
	} else {
		throw new Error(cart.responseBody);
	}
};

export const DeleteLineItemCartApi = async (cartId, lineId) => {
	const path = `/carts/${cartId}/items/${lineId}`;

	const cart = await api(path, {
		method: 'DELETE',
	});

	const return_code = cart.code ? cart.code : 201;
	if (return_code == 201 || return_code == 200) {
		return cart;
	} else {
		if (return_code == 404 || return_code == 204) {
			return { code: 404, responseBody: 'Cart is Cleared' }; // Cart is wiped out
		} else {
			throw new Error(cart.responseBody);
		}
	}
};

export const UpdateCartCustomer = async (cartId, customerId) => {
	var checkCartId = cartId ? cartId : 0;

	const requestBody = {
		customer_id: customerId,
	};
	if (cartId != 0) {
		const cart = await api(`/carts/${checkCartId}`, {
			method: 'PUT',
			body: JSON.stringify(requestBody),
		});

		const return_code = cart.code ? cart.code : 201;
		if (return_code == 201) {
			return cart;
		} else {
			if (return_code == 404 || return_code == 204) {
				return { code: 404, responseBody: 'Cart is Cleared' }; // Cart is wiped out
			} else {
				throw new Error(cart.responseBody);
			}
		}
	}
};
