export const SET_SEARCH = 'search/SET_SEARCH';
export const RESET_SEARCH = 'search/RESET_SEARCH';

const setSearch = search => ({
	type: SET_SEARCH,
	payload: {
		search,
	},
});

const resetSearch = () => ({
	type: RESET_SEARCH,
});

export const setSearchValue = search => async dispatch => {
	dispatch(setSearch(search));
};

export const resetSearchValue = search => async dispatch => {
	dispatch(resetSearch(search));
};
