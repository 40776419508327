import Reviews from '@src/components/category/Reviews';
import WhyBulletPoints from '@src/components/category/WhyBulletPoints';
import { getAllBrands, getAllCategories } from '@src/redux/actions/index';
import { getMasterProviderByDomain } from '@src/redux/actions/masterProvider';
import { getReviews } from '@src/redux/actions/reviews';
import blurredBackground from '@src/static/images/landing/blurred-background.jpg';
import commercial from '@src/static/images/landing/commercial.jpg';
import dynamic from 'next/dynamic';
import { NextSeo } from 'next-seo';
import React, { useEffect } from 'react';
import { useState } from 'react';

import affirmDark from '@src/static/images/logos/affirm-dark.png';
import parsePhoneNumber from 'libphonenumber-js';
import { findPhoneNumbersInText } from 'libphonenumber-js';
import Link from 'next/link';
const VideoModalCommercial = dynamic(import('@src/components/modals/VideoModalCommercial'));
const HomeCategories = dynamic(() => import('@src/components/category/CategoryHomePage'));
const BrandsSection = dynamic(() => import('@src/components/layout/brandssection'));
import WhyFeatures from '@src/components/city/sections/WhyFeatures';


const HomePage = props => {
	const [masterProvider, setMasterProvider] = useState({ isActive: false });
	const [showReviews, setShowReviews] = useState(true);
	const [showModalCommercial, setShowModalCommercial] = useState(false);
	
	 
	var phoneNumber = '(800)228-8379'; // TODO: Move all Phone and Email to a Global Object Parsing ( )
	var googleNumber = 'tel:+18002288379';
	var emailAddress = 'info@1800battery.com';

	const geolocation = props.geolocation;

	if (masterProvider && masterProvider.isActive) {
		phoneNumber = masterProvider.phoneNumber ? masterProvider.phoneNumber : '';
		emailAddress = masterProvider.emailAddress ? masterProvider.emailAddress : '';
	}

	googleNumber = findPhoneNumbersInText(phoneNumber, 'US');
	if (googleNumber.length > 0) {
		googleNumber = parsePhoneNumber(googleNumber[0].number.number);
		phoneNumber = googleNumber.formatNational();
		googleNumber = googleNumber.getURI();
	}

	var keywords = [];
	keywords.push((props.category ? props.category.name : 'Vehicle') + ' Battery');
	keywords.push(geolocation ? geolocation.formatted_address : 'Your City');
	keywords.push('Same Day Delivery');
	keywords.push('Professional Installation');
	keywords.push('National Warranty');
	keywords.push('Near Me');

	var hostname = '1800battery.com';
	if (typeof window !== 'undefined') {
		hostname = window.location.hostname;
	}


	const url =  'vehicle-batteries-car-or-truck';
	const isManual = false;
	const myHref = isManual ? `/${url}` : `/category/vehicle-batteries-car-or-truck`;
	const asVar = isManual ? url : `/category/${url}`;



	useEffect(() => {
		if (props.masterProvider) {
			setMasterProvider(props.masterProvider);
			setShowReviews(true);
		} else {
			if (window.localStorage === 'undefined') {
				const mp = window.localStorage.getItem('masterprovider');
				if (mp) {
					const mpParsed = JSON.parse(mp);
					setMasterProvider(mpParsed);
					setShowReviews(true);
				}
			}
		}
	}, [props]);

	var { allCategories, brands, reviews } = props;

	return (
		<div>
			<NextSeo
				title={`The Largest Battery Marketplace in North America | 1-800-Battery`}
				description={`Roadside and onsite battery assistance services, combined with a nationwide support system for warranty and support.`}
				canonical={`https://1800battery.com`}
			/>

			<VideoModalCommercial open={showModalCommercial} onCloseModal={() => setShowModalCommercial(false)} />
			{allCategories && (
				<HomeCategories
					categories={allCategories?.filter(category => category.showOnHomePage) || []}
					allCategoriesCollection={allCategories}
					masterProvider={masterProvider}
				/>
			)}

			{!masterProvider.isActive && (
				<div className="container">
					<div className="!p-5 md:!p-10" id="services">
						<WhyFeatures title="Why Choose Us" />
					</div>
				</div>
			)}

			<div className="container">
				<div
					className="commercial"
					style={{
						background: `url(${blurredBackground.src}) center center`,
					}}>
					<div className="inner" onClick={() => setShowModalCommercial(true)}>
						<div className="tv">
							<div className="icon-container">
								<span>
									<i className="fas fa-play-circle"></i>
								</span>
							</div>
							<img src={commercial.src} alt="commercial" />
						</div>
						<p>Watch our commercial</p>
					</div>
				</div>
			</div>


			{showReviews && (
				<div
					className="!mt-4"
					style={{
						backgroundImage: 'url(/images/happy-clients.jpg)',
						backgroundSize: '700px auto',
						backgroundPosition: 'top right',
						backgroundRepeat: 'no-repeat',
					}}
				>
					<div className="container bg-white/80 lg:bg-transparent">
						<Reviews href={myHref} type="static-v2" />
					</div>
				</div>
			)}

			{brands && <BrandsSection brandList={brands} masterProvider={masterProvider} />}

			{!masterProvider.isActive && (
				<div className="container">
					<div className="klaviyo-form-VG2KPf"></div>
				</div>
			)}
		</div>
	);
};

export async function getStaticProps() {
	const masterProvider = await getMasterProviderByDomain(process.env.NEXT_PUBLIC_HOSTNAME);
	const allCategories = await getAllCategories(masterProvider);
	const brands = await getAllBrands(masterProvider);
	const reviews = await getReviews();

	return {
		props: {
			masterProvider,
			allCategories,
			brands,
			reviews,
		},
	};
}

export default HomePage;
