import { SET_PRICELEVEL } from '../actions/pricelevel';

const initialState = {
	priceLevel: null,
};

export default function pricelevelReducer(state = initialState, action) {
	switch (action.type) {
		case SET_PRICELEVEL:
			return action.payload;

		default:
			return state;
	}
}
