import axios from 'axios';
import * as rax from 'retry-axios';

import * as types from '../constants/actiontypes';
import config from './config';

const apiPath = config.apiUrl;
const interceptorId = rax.attach();

export const getProduct = ( productid, 	showpriceonly ) => {
	// Use loading here
	
	return async (dispatch, getState) => {
		dispatch({
			type: types.GET_PRODUCT_LOADING_STATUS,
			payload: true,
		});

		const { geolocation } = getState();
		var path = `${apiPath}/product/${productid}`;
		var paramtoken = '?';
		if (geolocation.latitude && geolocation.longitude) {
			path = path + `?lat=${geolocation.latitude}&lng=${geolocation.longitude}`;
			paramtoken = '&';
		}
		if ( showpriceonly ){
			path = path + paramtoken + 'showpriceonly=true'
		}

		return await axios
			.get(path, { crossdomain: true })
			.then(response => {
				dispatch({
					type: types.GET_PRODUCT,
					payload: response.data,
				});
				dispatch({
					type: types.GET_PRODUCT_LOADING_STATUS,
					payload: false,
				});
			})
			.catch(error => {
				dispatch({
					type: types.GET_PRODUCT_LOADING_STATUS,
					payload: false,
				});
				console.log('Error', error);
			});
	};
};

export const setProduct = setproduct => {
	return async (dispatch, getState) => {
		const { product } = getState();
		dispatch({
			type: types.GET_PRODUCT,
			payload: { ...setproduct, brand: product?.item?.brand },
		});
	};
};
