import * as geoLocationActions from '@src/redux/actions/geolocation';
import { setMasterProviderAction } from '@src/redux/actions/masterProvider';
import { getReviews, setReviewsValue } from '@src/redux/actions/reviews.js';
import Cookies from 'js-cookie';
import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

const Header = dynamic(() => import('../home/Header'));
const CheckoutHeader = dynamic(() => import('../home/Header/checkout'));
const Footer = dynamic(() => import('../home/Footer'));
import CouponCodePromoModal from '@src/components/modals/CouponCodePromoModal';

const Layout = props => {
	const [masterProvider, setMasterProvider] = useState({ isActive: false });

	useEffect(() => {
		const geoLoc = Cookies.get('geolocation');
		if (geoLoc) {
			props.setLocation(JSON.parse(geoLoc));
		}
		getReviews().then(data => props.setReviewsValue(data));
		const masterProvider = Cookies.get('masterprovider');
		if (masterProvider) {
			props.setMasterProvider(JSON.parse(masterProvider));
			setMasterProvider(JSON.parse(masterProvider));
		}
	}, []);

	const pathname = props.children.props.router ? props.children.props.router.pathname : '';

	const promoCode = props.children?.props?.router?.state?.query?.promoCode || null;
	const promoCodeDiscountValue = props.children?.props?.router?.state?.query?.discount || null;
	const installerId = props.children?.props?.router?.state?.query?.installerId || null;

	const isMasterProvider = masterProvider && masterProvider.isActive;
	const theme = isMasterProvider ? masterProvider.domain.split('.')[0] : 'default';

	return (
		<div className={`theme-${theme}`}>
			{pathname == '/checkout' || pathname == '/provider-order-template' ? (
				<CheckoutHeader masterProvider={masterProvider} />
			) : (
				<Header
					masterProvider={masterProvider}
					isSimplified={pathname.includes('city') || pathname.includes('cities') || pathname.includes('jumpstart')}
				/>
			)}
			<CouponCodePromoModal promoCode={promoCode} promoCodeDiscountValue={promoCodeDiscountValue} />

			{/* <SetInstallerAvailability installerId={installerId} /> */}

			{props.children}
			{pathname == '/checkout' || pathname == '/provider-order-template' ? (
				<></>
			) : (
				<Footer masterProvider={masterProvider} />
			)}
		</div>
	);
};
const mapStateToProps = state => ({
	reviews: state.reviews,
});

const mapDispatchToProps = dispatch => ({
	setReviewsValue: payload => dispatch(setReviewsValue(payload)),
	setLocation: payload => dispatch(geoLocationActions.setLocation(payload)),
	setMasterProvider: payload => dispatch(setMasterProviderAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
