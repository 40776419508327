export const SET_PRICELEVEL = 'pricelevel/SET_PRICELEVEL';

const setPriceLevel = priceLevel => ({
	type: SET_PRICELEVEL,
	payload: {
		priceLevel,
	},
});

export const setSelectedPriceLevel = priceLevel => async dispatch => {
	dispatch(setPriceLevel(priceLevel));
};
