import Script from 'next/script';

export const YWXI_conversion = ({ orderId, amount, email, firstName, lastName, country }) => (
	<Script
		src="https://cdn.ywxi.net/js/conversion.js"
		className="trustedsite-track-conversion"
		data-type="purchase"
		data-orderid={orderId}
		data-amount={amount}
		data-email={email}
		data-firstname={firstName}
		data-lastname={lastName}
		data-country={country}
	/>
);

export const YWXI_main = () => (
	<Script 
		type="text/javascript" 
		src="https://cdn.ywxi.net/js/1.js" 
		async
	/>
);
