import * as searchActions from '@src/redux/actions/search';
import algoliasearch from 'algoliasearch/lite';
import Router from 'next/router';
import React, { Component } from 'react';
import { Configure, Index, InstantSearch } from 'react-instantsearch-dom';
import { connect } from 'react-redux';

import PlacesSearch from '../PlacesSearch';
import AutoComplete from './AutoComplete';

const searchClient = algoliasearch(
	'CHT94VPAPE', // TODO: Move to ENV Variables via Now Secrets...
	'4126efe6cdbbfb49afcf0d8cd0e8c5a6'
);

let categorySelection,
	categorySelectionInput = null;

class SmartSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			category: null,
			searching: false,
		};
	}

	componentDidMount() {
		categorySelection = document.getElementById('category-selection');
		categorySelectionInput = categorySelection.querySelectorAll('input')[0];
		categorySelectionInput.className += 'data-hj-whitelist';
	}

	search = async () => {
		if (categorySelectionInput.value != undefined && categorySelectionInput.value != '') {
			this.setState({
				searching: true,
			});

			await this.props.setSearchValue({ query: categorySelectionInput.value });
			Router.push(
				`/search/[searchterm]`,
				`/search/${encodeURI(categorySelectionInput.value.replace(/\s+/g, '-').toLowerCase())}`
			);
		}
		this.setState({
			searching: false,
		});
	};

	render() {
		return (
			<div className="smart-search">
				<div className="mr-1 category-selection" id="category-selection">
					<div className="autosuggest-container">
						<InstantSearch indexName="site_categories" searchClient={searchClient}>
							<Configure hitsPerPage={6} />
							<AutoComplete
								theme={autosuggestTheme}
								onSuggestionSelected={(event, { suggestion, suggestionValue }) => {
									// Parse URL to correct path, for Client Side Routing...
									// TODO: make a function... or put Route into a variable on Search DB...

									var urlParse = suggestion.urlSlug.split('/');
									var path = '';
									switch (urlParse[0]) {
										case 'product':
											path = '/product/[productid]';
											break;
										case 'brand':
											path = '/brand/[brandid]';
											break;
										case 'category':
											path = '/category/[categoryid]';
											break;
										default:
											path = '/category/[categoryid]';
									}

									Router.push(path, `/${suggestion.urlSlug}`);
								}}
							/>
							<Index indexName="site_categories" />
							<Index indexName="site_crossreference" />
							<Index indexName="site_products" />
						</InstantSearch>
					</div>
				</div>

				<PlacesSearch />
			</div>
		);
	}
}

const mapStateToProps = state => ({
	search: state.search,
});

const mapDispatchToProps = dispatch => ({
	setSearchValue: payload => dispatch(searchActions.setSearchValue(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SmartSearch);

const autosuggestTheme = {
	container: {
		position: 'relative',
	},
	input: {
		paddingRight: '10px',
		paddingLeft: '16px',
		lineHeight: '40px',
		height: '40px',
		border: '1px solid #CCC',
		outline: 'none',
		font: 'inherit',
	},

	inputFocused: {
		outline: 'none',
	},
	inputOpen: {
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
	},
	suggestionsContainer: {
		display: 'none',
	},
	suggestionsContainerOpen: {
		display: 'block',
		position: 'absolute',
		top: 39,
		width: '100%',
		border: 'none',
		backgroundColor: '#fff',
		fontFamily: 'inherit',
		borderRadius: '3px',
		zIndex: 2,
		boxShadow: '0 1px 10px rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1)',
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none',
	},
	suggestion: {
		cursor: 'pointer',
		padding: '10px',
		width: '100%',
	},
	suggestionHighlighted: {
		backgroundColor: '#ddd',
	},
};
