import { RESET_FILTER, SET_FILTER } from '../actions/filters';

const initialState = {
	services: [],
	categories: [],
	brands: [],
};

export default function filterReducer(state = initialState, action) {
	switch (action.type) {
		case SET_FILTER:
			return action.payload.filter;
		case RESET_FILTER:
			return initialState;
		default:
			return state;
	}
}
