// Cart Section
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const INCREMENT_QTY = 'INCREMENT_QTY';
export const DECREMENT_QTY = 'DECREMENT_QTY';
export const UPDATE_QTY = 'UPDATE_QTY';
export const CLEAR_CART = 'CLEAR_CART';
export const ADD_CUSTOMER_ID = 'ADD_CUSTOMER_ID';
export const UPDATE_CHECKOUT_STEP = 'UPDATE_CHECKOUT_STEP';
export const UPDATE_SELECTED_PROVIDER = 'UPDATE_SELECTED_PROVIDER';

// WishList
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';

// Compare
export const ADD_TO_COMPARE = 'ADD_TO_COMPARE';
export const REMOVE_FROM_COMPARE = 'REMOVE_FROM_COMPARE';

// Get Categories
export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES';
export const GET_CATEGORY = 'GET_CATEGORY';
export const RESET_CATEGORY = 'RESET_CATEGORY';
export const SET_VIN = 'SET_VIN';

// Fitment
export const ADD_FITMENT_OPTIONS = "ADD_FITMENT_OPTIONS";
export const ADD_FITMENT_TARGET = "ADD_FITMENT_TARGET";

// Get RECEIVE PRODUCT
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_LOADING_STATUS = 'GET_PRODUCT_LOADING_STATUS';
export const SORT_PRODUCT_COLLECTION = 'SORT_PRODUCT_COLLECTION';

// Get Brands
export const RECEIVE_BRANDS = 'RECEIVE_BRANDS';

export const RECEIVE_SERVICES = 'RECEIVE_SERVICES';

export const RECEIVE_COUNTRIES = 'RECEIVE_COUNTRIES';

export const RECEIVE_VEHICLE = 'RECEIVE_VEHICLE';
