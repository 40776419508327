import { RESET_ROADSIDE, SET_ROADSIDE } from '../actions/roadside';

const initialState = {
	request: null,
};

export default function roadsideReducer(state = initialState, action) {
	switch (action.type) {
		case SET_ROADSIDE:
			return { ...state, request: action.payload.request };
		case RESET_ROADSIDE:
			return initialState;
		default:
			return state;
	}
}
