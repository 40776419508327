import React from 'react';
import { GladlyLoad, GladlyInit } from '@util/gladly';

export default function WebChat() {  
	return(
    <>
      <GladlyLoad />
      <GladlyInit />

      <button id="custom-gladly-chat-button" onClick={() => Gladly.show()}>
        <div className="icon-text-container" id="with-no-active-chat">
          <div className="icon-container">
            <i className="fas fa-comment-alt-dots"></i>
          </div>
          <div className="text">
            <span className="title">Need Help?</span>
            <span className="subtitle">Chat with us!</span>
          </div>

        </div>
        <div id="with-active-chat">
          <span>CONTINUE EXISTING CHAT</span>
          <div id="with-unread-message">
            <span> (unread message)</span>
          </div>
        </div>
      </button>
    </>
  )
}
