import * as types from '../constants/actiontypes';
export const GET_CHECKOUT = 'checkout/GET_CHECKOUT';
export const RESET_CHECKOUT = 'checkout/RESET_CHECKOUT';
export const UPDATE_CHECKOUT_STEP = types.UPDATE_CHECKOUT_STEP;
import { getCheckoutApi } from '../../bigcommerce/api/checkout/get-checkout';
import Router from 'next/router';
export const getCheckout = cartId => async dispatch => {
	var cartIdCheck = cartId ? cartId : 0;

	return await getCheckoutApi(cartIdCheck)
		.then(response => {
			const responseCode = response.code ? response.code : 200;
			if (responseCode != 200) {
				dispatch({
					type: RESET_CHECKOUT,
				});

				if (responseCode == 404) {
					// Clear Cart
					dispatch({
						type: types.CLEAR_CART,
					});
					Router.push('/cart').then(() => window.scrollTo(0, 0));
				}
			} else {
				dispatch({
					type: GET_CHECKOUT,
					data: response,
				});
			}
		})
		.catch(error => {
			throw error;
		});
};

export const updateCheckoutResult = result => async dispatch => {
	await dispatch({
		type: GET_CHECKOUT,
		data: result,
	});
};

export const updateCheckoutStep = step => dispatch => {
	dispatch({
		type: types.UPDATE_CHECKOUT_STEP,
		payload: { step: step, cartStep: step },
	});
};

export const resetCheckout = () => async dispatch => {
	dispatch({
		type: RESET_CHECKOUT,
		data: null,
	});
};
