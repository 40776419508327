import * as types from '../constants/actiontypes';
import { getCountries } from '../../bigcommerce/api/checkout/get-checkout';

export const getAllCountries = ID => {
	return async (dispatch, getState) => {
		const { countries } = await getState();
		if (countries.countries.length > 0) {
			return;
		}

		return await getCountries()
			.then(response => {
				dispatch({
					type: types.RECEIVE_COUNTRIES,
					data: response,
				});
			})
			.catch(error => {
				console.log(error);
			});
	};
};
