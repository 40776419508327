import * as types from '../constants/actiontypes';

const initialState = {
	countries: [],
};

const Brands = (state = initialState, action) => {
	switch (action.type) {
		case types.RECEIVE_COUNTRIES:
			return { ...state, countries: action.data };

		default:
			return state;
	}
};
export default Brands;
