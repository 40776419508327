import React from 'react';
import NextLink from 'next/link';

const Link = ({ children, href, ...other }) => {
	// Pass Any internal link to Next.js Link, for anything else, use <a> tag
	const internal = /^\/(?!\/)/.test(href);

	// For root page, use index.js, for rest use [...slug].js
	var page = href === '/' ? '/' : '/[...slug]';
	var slug = href.split('/');

	if (slug.length > 2) {
		if (slug[1] == 'category') {
			page = `/category/[categoryid]?categoryid=${slug[2]}`;
		}

		if (slug[1] == 'product') {
			page = `/product/[productid]?productid=${slug[2]}`;
		}
	}

	if (internal) {
		return (
			<NextLink href={page} as={href}>
				<a {...other}>{children}</a>
			</NextLink>
		);
	}

	return (
		<a href={href} {...other}>
			{children}
		</a>
	);
};

export default Link;
