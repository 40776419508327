import { api } from '../api-client';
import { setCustomer } from '../../../utils/customer';

export const getCustomer = async ({ username, password }) => {
	const requestBody = {
		username: username,
		password: password,
	};

	const customer = await api(`/customer/login/`, {
		method: 'POST',
		body: JSON.stringify(requestBody),
	});
	if (customer.error == undefined) {
		setCustomer({ customer });
		return customer;
	} else {
		throw new Error(customer.error);
	}
};
