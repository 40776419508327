import axios from 'axios';
export const SET_REVIEWS = 'reviews/SET_REVIEWS';

const setReviews = reviews => ({
	type: SET_REVIEWS,
	payload: {
		reviews,
	},
});

export const setReviewsValue = reviews => async dispatch => {
	dispatch(setReviews(reviews));
};

export const getReviews = async () => {
	const { data } = await axios.get('https://api.reviews.io/merchant/latest?store=1800battery.com-0900');
	return data;
};
