import { api } from '../api-client';

export const getCheckoutApi = async cartId => {
	var checkCartId = cartId ? cartId : 0;

	const checkout = await api(`/checkout/${checkCartId}`, {
		method: 'GET',
	});

	return checkout;
};

export const getCountries = async () => {
	const result = await api(`/checkout/countries/list`, {
		method: 'GET',
	});
	return result;
};

export const setBillingAddress = async (checkoutId, requestBody) => {
	const address = await api(`/checkout/${checkoutId}/billingaddress`, {
		method: 'POST',
		body: JSON.stringify(requestBody),
	});
	return address;
};

export const setShippingAddress = async (checkoutId, requestBody) => {
	const address = await api(`/checkout/${checkoutId}/consignments`, {
		method: 'POST',
		body: JSON.stringify(requestBody),
	});
	return address;
};

export const putShippingAddressOrShippingOption = async (checkoutId, consignmentId, requestBody) => {
	const consignment = await api(`/checkout/${checkoutId}/consignments/${consignmentId}`, {
		method: 'PUT',
		body: JSON.stringify(requestBody),
	});
	return consignment;
};

export const setCouponCode = async (checkoutId, requestBody) => {
	const coupon = await api(`/checkout/${checkoutId}/coupons`, {
		method: 'POST',
		body: JSON.stringify(requestBody),
	});
	return coupon;
};

export const createOrder = async (checkoutId, requestBody) => {
	const order = await api(`/checkout/${checkoutId}/createorder`, {
		method: 'POST',
		body: JSON.stringify(requestBody),
	});
	return order;
} 

export const createBigCommercePayment = async (orderId, requestBody) => {
	const payment = await api(`/checkout/createpayment/${orderId}`, {
		method: 'POST',
		body: JSON.stringify(requestBody),
	});
	return payment;
};

export const getOrderPaymentToken = async (orderId) => {
	const token = await api(`/checkout/paymenttoken/order/${orderId}`, {
		method: 'GET' 
	});
	return token;
};


export const updatePayment = async (orderId, requestBody) => {
	const order = await api(`/checkout/${orderId}/updatepayment`, {
		method: 'POST',
		body: JSON.stringify(requestBody),
	});
	return order;
};
