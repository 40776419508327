import { RESET_PROVIDERLOCATION, SET_PROVIDERLOCATION } from '../actions/providerLocation';

const initialState = {
	providerLocation: null,
};

export default function providerReducer(state = initialState, action) {
	switch (action.type) {
		case SET_PROVIDERLOCATION:
			return action.payload.providerLocation;
		case RESET_PROVIDERLOCATION:
			return initialState;
		default:
			return state;
	}
}
