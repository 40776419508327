import * as searchActions from '@src/redux/actions/search';
import Router from 'next/router';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AutoSuggest from 'react-autosuggest';
import toast from 'react-hot-toast';
import { connectAutoComplete, Highlight } from 'react-instantsearch-dom';
import { connect } from 'react-redux';

let categorySelection,
	categorySelectionInput = null;

class AutoComplete extends Component {
	static propTypes = {
		hits: PropTypes.arrayOf(PropTypes.object).isRequired,
		currentRefinement: PropTypes.string.isRequired,
		refine: PropTypes.func.isRequired,
		onSuggestionSelected: PropTypes.func.isRequired,
	};

	state = {
		value: this.props.currentRefinement,
	};

	componentDidMount() {
		categorySelection = document.getElementById('category-selection');
		categorySelectionInput = categorySelection.querySelectorAll('input')[0];
	}

	onChange = (event, { newValue }) => {
		this.setState({
			value: newValue,
		});
	};

	onSuggestionsFetchRequested = ({ value }) => {
		this.props.refine(value);
	};

	onSuggestionsClearRequested = () => {
		this.props.refine();
	};

	getSuggestionValue(hit) {
		return hit.name;
	}

	renderSuggestion(hit) {
		return (
			<div className="custom-highlight">
				{hit.imageUrl && <img src={hit.imageUrl} alt={hit.name} />}
				{hit.CategoryImageURL && <img src={hit.CategoryImageURL} alt={hit.name} />}
				{!hit.imageUrl || (hit.CategoryImageURL && <i className="fas fa-2x fa-car-battery"></i>)}
				<Highlight attribute="name" hit={hit} tagName="mark" />
				{hit.CatImageUrl && <small className="mr-1 ml-1">by</small>}
				{hit.CatImageUrl && <img className="smaller-thumb" src={hit.CatImageUrl} alt={hit.name} />}
			</div>
		);
	}

	renderSectionTitle(section) {
		if (section.hits <= 0) {
			return '';
		}
		var sectionTitle = '';
		switch (section.index) {
			case 'site_categories':
				// Can check Hits too...
				sectionTitle = 'Categories';
				break;
			case 'site_crossreference':
				sectionTitle = 'Fits';
				break;
			case 'site_products':
				sectionTitle = 'Products';
				break;
		}

		return <p className="section-title">{sectionTitle}</p>;
	}

	getSectionSuggestions(section) {
		return section.hits;
	}

	onKeyDown = event => {
		const { keyCode } = event;

		if (keyCode === 13 && !this.suggestionSelected) {
			var totalHits = 0;
			var searchterm = this.state.value.replace('/', '');

			if (this.props.hits.length > 0) {
				this.props.hits.map(x => {
					totalHits = totalHits + x.hits.length;
				});
			}
			if (totalHits > 0) {
				this.props.setSearchValue({ query: searchterm });

				Router.push(`/search/[searchterm]`, `/search/${searchterm}`).then(() => {
					if (window) {
						window.scrollTo(0, 0);
					}
				});

				categorySelectionInput.blur();
			} else {
				toast.error(`Search will yield no results!`);
			}
		}
		this.suggestionSelected = false;
	};

	render() {
		const { hits, onSuggestionSelected, theme, placeholder } = this.props;
		const { value } = this.state;

		const inputProps = {
			placeholder: 'Enter Search Here',
			onChange: this.onChange,
			onKeyDown: this.onKeyDown,
			value,
		};

		return (
			<AutoSuggest
				focusInputOnSuggestionClick={false}
				suggestions={hits}
				multiSection={true}
				onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
				onSuggestionsClearRequested={this.onSuggestionsClearRequested}
				onSuggestionSelected={onSuggestionSelected}
				getSuggestionValue={this.getSuggestionValue}
				renderSuggestion={this.renderSuggestion}
				inputProps={inputProps}
				renderSectionTitle={this.renderSectionTitle}
				getSectionSuggestions={this.getSectionSuggestions}
				theme={theme}
			/>
		);
	}
}

const mapStateToProps = state => ({
	search: state.search,
});

const mapDispatchToProps = dispatch => ({
	setSearchValue: payload => dispatch(searchActions.setSearchValue(payload)),
});

export default connectAutoComplete(connect(mapStateToProps, mapDispatchToProps)(AutoComplete));
