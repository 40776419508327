import axios from 'axios';

import { removeMasterProviderCookie, setMasterProviderCookie } from '../../utils/masterprovider';

export const SET_MASTERPROVIDER = 'masterProvider/SET_MASTERPROVIDER';
export const RESET_MASTERPROVIDER = 'masterProvider/RESET_MASTERPROVIDER';

export const setMasterProviderAction = provider => ({
	type: SET_MASTERPROVIDER,
	payload: {
		provider,
	},
});

const resetMasterProviderAction = () => ({
	type: RESET_MASTERPROVIDER,
});

export const resetMasterProvider = () => async dispatch => {
	removeMasterProviderCookie();
	dispatch(resetMasterProviderAction());
};

export const setDomain = domain => async dispatch => {
	console.log('DOMAIN', domain);
	let url = `https://q5q34l0vhg.execute-api.us-west-2.amazonaws.com/Prod/api/providers/domain/${domain}`; // Call from Cache..

	var result = await axios
		.get(url, { crossdomain: true })
		.then(response => {
			return response.data;
		})
		.catch(error => {
			throw new Error(`Error Loading Domain Information ${error}`);
		});

	console.log('RES', result);
	if (result) {
		var provider = result;
		try {
			var socialSettings = JSON.parse(result.socialSettings);
			provider.socialSettings = socialSettings;
		} catch {
			provider.socialSettings = null;
		}

		provider.isActive = true;
		dispatch(setMasterProvider(provider));
	}

	// Call API to domain
};

export const getMasterProviderByDomain = async domain => {
	if (domain && domain !== '1800battery.com' && domain !== 'localhost') {
		let url = `https://q5q34l0vhg.execute-api.us-west-2.amazonaws.com/Prod/api/providers/domain/${domain}`;

		var { data } = await axios.get(url, { crossdomain: true });
		return { ...data, isActive: true };
	}
	return { isActive: false };
};

export const setMasterProvider = provider => async dispatch => {
	setMasterProviderCookie(provider);
	dispatch(setMasterProviderAction(provider));
};
