import { LOGIN_SUCCESS, LOGOUT } from '../actions/customer';

const initialState = null;

export default function customerReducer(state = initialState, action) {
	switch (action.type) {
		case LOGIN_SUCCESS:
			return action.payload.customer;
		case LOGOUT:
			return initialState;
		default:
			return state;
	}
}
