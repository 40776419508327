import Script from 'next/script';

export const Gobot = () => {
	return (
		<Script
			strategy="afterInteractive"
			type="text/javascript"
			src="https://track.getgobot.com/gobot/js/pixel.js?key=-ODBD8GJU-FVo0xXMXNJ"
		></Script>
	);
};
