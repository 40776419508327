import axios from 'axios';
import * as rax from 'retry-axios';

import config from '../redux/actions/config';

const apiPath = config.apiUrl;
const interceptorId = rax.attach();

export async function getLocationProps(locationId, geolocation) {
	var location = null;
	var errorAxios = null;

	var path = `${apiPath}/providerlocations/${locationId}`;
	if (geolocation && geolocation.latitude != undefined && geolocation.latitude != null) {
		if (geolocation.latitude && geolocation.longitude) {
			path = path + `?GoogleData=true&lat=${geolocation.latitude}&lng=${geolocation.longitude}`;
		}
	} 

	await axios
		.get(
			path, // TODO: Make GoogleData an options later.. but for now test and build away
			{ crossdomain: true }
		)
		.then(response => { 
			location = response.data;
		})
		.catch(error => {
			errorAxios = error;
		});

	return { location: location, error: errorAxios };
}
