import { RESET_GEOLOCATION, SET_GEOLOCATION, SET_GEOLOCATION_LOADING } from '../actions/geolocation';

const initialState = {
	latitude: null,
	longitude: null,
	formatType: 'city',
	isLocationLoading: false,
};

export default function geoLocationReducer(state = initialState, action) {
	switch (action.type) {
		case SET_GEOLOCATION:
			return { ...state, ...action.payload.geolocation };
		case SET_GEOLOCATION_LOADING:
			return { ...state, isLocationLoading: action.payload };
		case RESET_GEOLOCATION:
			return initialState;
		default:
			return state;
	}
}
