import fetch from 'isomorphic-fetch';
import config from '../config';

const makeRequest = async (url, options, token) => {
	var result = await fetch(`${config.apiUrl}${url}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		...options,
	})
		.then(response => {
			return response;
		})
		.catch(error => {
			console.log(error);
			return error;
		});
	return result;
};

export const api = async (url, options) => {
	// Grab the token from the store or from the API
	let token = ''; //getToken() || (await getGuestToken());

	try {
		// Do the request

		let response = await makeRequest(url, options, token);

		var value = null;

		try {
			value = await response.json();
		} catch {
			// Force Value to 204 CartClear --- Hack... Stupid API Json Fails on empty cart...
			value = { code: 204 };
		}

		return value;
	} catch (e) {
		// Place to handle global api errors
		console.log(e);
		throw new Error('Bad API Gateway Response - BigCommerce >> MarketRing Server');
	}
};
