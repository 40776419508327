import React from 'react';
import Modal from 'react-responsive-modal';
import YouTube from 'react-youtube';

const opts = {
	height: '390',
	width: '640',
	playerVars: {
		autoplay: 1,
		rel: 0,
	},
};

const VideoModal = ({ open, onCloseModal }) => {
	return (
		<Modal
			classNames={{
				modal: `video-modal`,
				closeButton: 'close-btn',
			}}
			open={open}
			onClose={() => onCloseModal()}>
			<div className="inner">
				<YouTube videoId="4vVvgXTTb84" opts={opts} />
			</div>
		</Modal>
	);
};

export default VideoModal;
