import Cookies from 'js-cookie';

import { isBrowser } from './is-browser';

export const getMasterProvider = () => {
	const masterProvider = isBrowser() && window.localStorage.getItem('masterprovider');
	if (masterProvider) {
		try {
			return JSON.parse(masterProvider);
		} catch (err) {
			console.log(err);
			return null;
		}
	}
	return null;
};

export const setMasterProviderCookie = masterProvider => {
	const expires = new Date();
	expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
	Cookies.set('masterprovider', JSON.stringify(masterProvider));

	isBrowser() && window.localStorage.setItem('masterprovider', JSON.stringify(masterProvider));
};

export const removeMasterProviderCookie = () => {
	Cookies.remove('masterprovider');
	isBrowser() && window.localStorage.removeItem('masterprovider');
};
