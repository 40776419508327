import * as types from '../constants/actiontypes';
import toast from 'react-hot-toast';
import Router from 'next/router';

import {
	addCart,
	UpdateLineItemCart,
	DeleteLineItemCartApi,
	UpdateCartCustomer,
} from '../../bigcommerce/api/cart/get-cart';
// export const addToCartUnsafe = (product, qty) => ({
//   type: types.ADD_TO_CART,
//   product,
//   qty
// });

const addCustomerId = bigCommerce => ({
	type: types.ADD_CUSTOMER_ID,
	payload: {
		bigCommerceCart: bigCommerce,
	},
});

export const addToCartUnsafe = (product, qty, checkout) => {
	return async (dispatch, getState) => {
		var { customer, cartlist } = getState();
		var customerId = customer ? customer.id : 0;
		var price = product.selectedProviderService ? product.selectedProviderService.providerId.Price : product.price;
		product.price = price;
		var cartId = cartlist.bigCommerceCart ? cartlist.bigCommerceCart.id : 0;
		cartId = await RemoveMileageProduct(product, cartlist, cartId, dispatch); // Should move to API someday.

		var index = cartlist.cart.findIndex(findProduct => findProduct.id === product.id);

		// const product_id = cartlist.cart[index];
		// var bcIndex = cartlist.bigCommerceCart.line_items.physical_items.findIndex(
		//   findBCProduct => findBCProduct.product_id === product_id.bigCommerceId
		// );

		return await addCart(product, qty, customerId, cartId)
			.then(response => {
				if (response.responseBody != undefined) {
					toast.error('Error Adding to Cart');
					throw new Error('Error Adding to Cart');
				} else {
					dispatch({
						type: types.ADD_TO_CART,
						product,
						qty,
						bigCommerceCart: response,
					});

					if (product) {
						var trackItem = {
							cart_id: cartId,
							product_id: product.bigCommerceId,
							sku: product.sku,
							name: product.name,
							price: price,
							category: product.categoryId,
							brand: product.brandId,
							quantity: qty,
							variant: `Service:${product.selectedProviderService.serviceId},Provider:${product.selectedProviderService.providerId.ProviderId}`,
						};

						analytics.track('Product Added', trackItem);
						gbTracker.sendAddToCartEvent(
						{
							cart: {
								id: trackItem.cart_id,
								items: [
									{
										productId: product.bigCommerceId,
										title: product.name,
										price: price,
										quantity:  qty,
										category: product.categoryId || 2,
										sku: product.sku,
									}
								]
							}
						});
					}

					if (!checkout) {
						if (index == -1) {
							Router.push('/checkout').then(() => window.scrollTo(0, 0));
						} else {
							Router.push('/cart').then(() => window.scrollTo(0, 0));
						}

						toast.success('Item Added to Cart');
					}
				}
			})
			.catch(error => {
				throw error;
			});
	};
};

export const removeFromCart = product_id => {
	return async (dispatch, getState) => {
		const { cartlist } = getState();

		var cartId = cartlist.bigCommerceCart ? cartlist.bigCommerceCart.id : 0;
		var lineId = 'error';

		if (cartId && cartId !== 0) {
			var bcIndex = cartlist.bigCommerceCart.line_items.physical_items.findIndex(
				findBCProduct => findBCProduct.product_id === product_id.bigCommerceId
			);

			if (bcIndex != -1) {
				lineId = cartlist.bigCommerceCart.line_items.physical_items[bcIndex].id;
				// Remove Item Analytics Here...
			}
		}
		gbTracker.sendRemoveFromCartEvent({
			id: cartId,
			items: [
				{
					productId: product_id.bigCommerceId,
					title: product_id.name,
					price: product_id.price,
					quantity: product_id.qty,
					category: product_id.categoryId,
					sku: product_id.sku,
				}
			]
		})
		return await DeleteCartLine(cartId, lineId, dispatch, product_id, getState);
	};
};

export const incrementQty = (product, qty) => dispatch => {
	dispatch(addToCartUnsafe(product, qty));
};

export const addCustomerIdToCart = bigCommerce => dispatch => {
	dispatch(addCustomerId(bigCommerce));
};

export const updateQty = (product, qty) => {
	return async (dispatch, getState) => {
		const { cartlist } = getState();
		// Move Above Block to its own function
		var cartId = cartlist.bigCommerceCart ? cartlist.bigCommerceCart.id : 0;

		let updatedQty = qty;
		var index = cartlist.cart.findIndex(findProduct => findProduct.id === product);

		const product_id = cartlist.cart[index];
		var bcIndex = cartlist.bigCommerceCart.line_items.physical_items.findIndex(
			findBCProduct => findBCProduct.product_id === product_id.bigCommerceId
		);

		var lineId = 'error';
		if (bcIndex != -1) {
			lineId = cartlist.bigCommerceCart.line_items.physical_items[bcIndex].id;
		}
		// Move Above Block to its own function

		if (qty <= -1) {
			updatedQty = product_id.qty + qty;
		}

		if (updatedQty == 0) {
			return await DeleteCartLine(cartId, lineId, dispatch, product_id);
			// Delete Line Item
		}

		return await UpdateLineItemCart(product_id, updatedQty, cartId, lineId)
			.then(response => {
				if (response.status) {
					toast.error('Error Updating Cart Item');
					throw new Error('Error Updating Cart Item');
				}

				dispatch({
					type: types.UPDATE_QTY,
					productId: product_id.id,
					qty: updatedQty,
					bigCommerceCart: response,
				});
			})
			.catch(error => {
				throw error;
			});
	};
};

export const updateSelectedProvider = (product, providerId, serviceId) => {
	return async (dispatch, getState) => {
		if (!getState) {
			dispatch({ type: types.CLEAR_CART });
		}

		var { cartlist } = getState();
		// Move Above Block to its own function
		var cartId = cartlist.bigCommerceCart ? cartlist.bigCommerceCart.id : 0;
		cartId = await RemoveMileageProduct(product, cartlist, cartId, dispatch);

		var index = cartlist.cart.findIndex(findProduct => findProduct.id === product);

		var updateProduct = cartlist.cart[index];
		updateProduct.selectedProviderService.providerId = providerId;
		updateProduct.selectedProviderService.serviceId = serviceId;
		cartlist.cart[index] = updateProduct;
		dispatch({
			type: types.UPDATE_SELECTED_PROVIDER,
			cart: cartlist,
		});
	};
};

/* export const decrementQty = productId => ({
  type: types.DECREMENT_QTY,
  productId
}); */

export const decrementQty = productId => dispatch => {
	dispatch(updateQty(productId, -1));
};

export const clearCart = () => dispatch => {
	dispatch({
		type: types.CLEAR_CART,
	});
};

export const addToWishlistUnsafe = product => ({
	type: types.ADD_TO_WISHLIST,
	product,
});
export const removeFromWishlist = product_id => ({
	type: types.REMOVE_FROM_WISHLIST,
	product_id,
});

//it seems that I should probably use this as the basis for "Cart"
export const addToCart = (product, qty) => dispatch => {
	dispatch(addToCartUnsafe(product, qty));
};

export const addToCartAndRemoveWishlist = (product, qty) => dispatch => {
	dispatch(addToCartUnsafe(product, qty));
	dispatch(removeFromWishlist(product));
	toast.success('Item Added to Cart');
};

export const addToWishlist = product => dispatch => {
	dispatch(addToWishlistUnsafe(product));
	toast.success('Item Added to Favorites');
};

async function RemoveMileageProduct(product, cartlist, cartId, dispatch) {
	if (product.selectedProviderService) {
		const { MileageProductId } = product.selectedProviderService.providerId;

		if (MileageProductId > 0 && cartlist.bigCommerceCart) {
			var bcIndex = cartlist.bigCommerceCart.line_items.physical_items.findIndex(
				findBCProduct => findBCProduct.product_id === MileageProductId
			);

			// var test = await removeMileageFromCart(MileageProductId);
			var cartId = cartlist.bigCommerceCart ? cartlist.bigCommerceCart.id : 0;
			var lineId = 'error';
			if (bcIndex != -1) {
				lineId = cartlist.bigCommerceCart.line_items.physical_items[bcIndex].id;
				var product_id = { id: MileageProductId };
				cartId = await DeleteCartLine(cartId, lineId, dispatch, product_id);
			}
		}
	}
	return cartId;
}

async function DeleteCartLine(cartId, lineId, dispatch, product_id, getState) {
	var cartIdResponse = await DeleteLineItemCartApi(cartId, lineId)
		.then(response => {
			if (response.responseBody != undefined) {
				if (response.code == 404 || response.code == 422) {
					// Clear the Cart...  It is not no longer found...
					dispatch({
						type: types.CLEAR_CART,
					});

					return 0;
				} else {
					throw new Error('Error Deleting Cart Item'); // Need to Change Error Handling Next.Js
				}
			} else {
				dispatch({
					type: types.REMOVE_FROM_CART,
					productId: product_id.id,
					bigCommerceCart: response,
				});

				var { cartlist } = getState();
				if (cartlist.cart.length == 0) {
					dispatch({
						type: types.CLEAR_CART,
					});
				}

				return cartId;
			}
		})
		.catch(error => {
			console.log(error);
			throw error;
		});

	return cartIdResponse;
}