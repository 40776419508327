import axios from 'axios';
import * as rax from 'retry-axios';

import config from '../redux/actions/config';

const apiPath = config.apiUrl;
const interceptorId = rax.attach();

export async function getChildCategoryLoadOnly(categoryid, childCategory) {
	var categoryChildren = null;

	if (childCategory && childCategory.length > 0) {
		return childCategory;
	}

	if (categoryid) {
		await axios
			.get(`${apiPath}/crossreference/${categoryid}`, { crossdomain: true })
			.then(response => {
				categoryChildren = response.data;
			})
			.catch(error => {
				console.log(error);
			});
	}

	return categoryChildren;
}
