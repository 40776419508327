import cookie from 'react-cookies';

import { isBrowser } from './is-browser';

const expires = new Date();
expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);

export const getCustomer = () => {
	const customer = isBrowser() && window.localStorage.getItem('customer');
	if (customer) {
		try {
			return JSON.parse(customer);
		} catch (err) {
			return null;
		}
	}
	return null;
};

export const setCustomer = customer => {
	cookie.save('customer', JSON.stringify(customer), {
		path: '/',
		expires,
		maxAge: 1000,
		httpOnly: true,
		secure: true,
	});

	isBrowser() && window.localStorage.setItem('customer', JSON.stringify(customer));
};

export const removeCustomer = () => {
	cookie.remove('customer');
	isBrowser() && window.localStorage.removeItem('customer');
};
