import * as types from '../constants/actiontypes';

const initialState = {
	item: null,
	isLoading: false,
};

const Product = (state = initialState, action) => {
	switch (action.type) {
		case types.GET_PRODUCT:
			return { ...state, item: action.payload };

		case types.GET_PRODUCT_LOADING_STATUS:
			return { ...state, isLoading: action.payload };

		default:
			return state;
	}
};
export default Product;
