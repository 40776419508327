import { combineReducers } from 'redux';

import brandReducer from './reducers/brands';
import cartReducer from './reducers/cart';
import categoriesReducer from './reducers/categories';
import categoryReducer from './reducers/category';
import checkoutReducer from './reducers/checkout';
import compareReducer from './reducers/compare';
import countriesReducer from './reducers/countries';
import customerReducer from './reducers/customer';
import filtersReducer from './reducers/filters';
import geoLocationReducer from './reducers/geolocation';
import masterProviderReducer from './reducers/masterprovider';
import navigationReducer from './reducers/navigation';
import pricelevelReducer from './reducers/pricelevel';
import productReducer from './reducers/product';
import providerReducer from './reducers/provider';
import providerLocationReducer from './reducers/providerLocation';
import reviewsReducer from './reducers/reviews';
import roadsideReducer from './reducers/roadside';
import searchReducer from './reducers/search';
import servicesReducer from './reducers/services';
import wishlistReducer from './reducers/wishlist';

const rootReducer = combineReducers({
	categories: categoriesReducer,
	category: categoryReducer,
	cartlist: cartReducer,
	wishlist: wishlistReducer,
	compare: compareReducer,
	customer: customerReducer,
	geolocation: geoLocationReducer,
	checkout: checkoutReducer,
	search: searchReducer,
	product: productReducer,
	brands: brandReducer,
	services: servicesReducer,
	provider: providerReducer,
	filters: filtersReducer,
	countries: countriesReducer,
	navigation: navigationReducer,
	providerLocation: providerLocationReducer,
	masterProvider: masterProviderReducer,
	pricelevel: pricelevelReducer,
	roadside: roadsideReducer,
	reviews: reviewsReducer,
});

export default rootReducer;
