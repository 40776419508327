import REDIS_CACHE from '@src/constants/rediscache';
import axios from 'axios';

export const getAddressData = ({ address_components, formatted_address, geometry }) => {
	const data = {
		formattedAddress: '',
		area: '',
		city: '',
		postal: '',
		address: '',
		state: '',
		latitude: 0,
		longitude: 0,
	};

	data.formattedAddress = formatted_address;

	if (geometry) {
		data.latitude = geometry.location.lat();
		data.longitude = geometry.location.lng();
	}

	if (!address_components?.length) {
		return null;
	}

	address_components.forEach(address => {
		if (address.types && address.types.length !== 0) {
			address.types.forEach(addressType => {
				if ('sublocality_level_1' === addressType || 'country' === addressType || 'locality' === addressType) {
					data.area = address.short_name;
				}

				if ('administrative_area_level_2' === addressType || 'administrative_area_level_1' === addressType) {
					data.city = address.long_name;
				}

				if ('postal_code' === addressType) {
					data.postal = address.long_name;
				}

				if ('route' === addressType || 'street_number' === addressType) {
					data.address = address.long_name;
				}

				if ('administrative_area_level_1' === addressType) {
					data.state = address.short_name;
				}
			});
		}
	});

	return data;
};

export const requestLocation = (
	toast,
	setLoadingCurrentGeolocation,
	setLocationPermissionApprovedLoading,
	provideData,
	geolocation,
	onGeolocationFound
) => {
	if (!navigator.geolocation) {
		toast.error('Geolocation is not supported by your browser');
		this.requestLocation();
	}
	if (!navigator.geolocation) {
		toast.error('Geolocation is not supported by your browser');
		setLoadingCurrentGeolocation(false);
	} else {
		navigator.geolocation.getCurrentPosition(
			position => {
				const latitude = position.coords.latitude;
				const longitude = position.coords.longitude;
				setLoadingCurrentGeolocation(true);

				provideData(latitude, longitude, geolocation).then(data => {
					setLoadingCurrentGeolocation(false);
					setLocationPermissionApprovedLoading(false);
					onGeolocationFound(data);
				});
			},
			error => {
				if (error.code == 3 && !geolocation.latitude) {
					toast.error(
						'We were unable to retrieve your location. Please enter it manually in the location form'
					);
				}
				setLoadingCurrentGeolocation(false);
				setLocationPermissionApprovedLoading(false);
			},
			{
				timeout: 30000,
				enableHighAccuracy: true,
				maximumAge: Infinity,
			}
		);
	}
};

export const getIpAddress = async () => {
	var { data } = await axios.get(`https://geolocation-db.com/json/09ba3820-0f88-11eb-9ba6-e1dd7dece2b8`, {
		crossdomain: true,
	});

	return data;
};

export const getGeoLocationByLatLong = async (lat, long, geolocation) => {
	const ipaddress = await getIpAddress();
	var type = 'IP Address';

	var path = `${REDIS_CACHE}/geolocation?`;
	if (ipaddress) {
		path = path + `ipaddress=${ipaddress.IPv4}`;
		if (!lat && !geolocation.latitude) {
			lat = ipaddress.latitude;
			long = ipaddress.longitude;
		}
	}

	if (lat && long) {
		type = 'GeoLocation';
		path = path + `&latitude=${lat}&longitude=${long}`;
	} else {
		if (geolocation.latitude && geolocation.longitude) {
			type = geolocation.lookupType ? geolocation.lookupType : 'State Set';
			path = path + `&latitude=${geolocation.latitude}&longitude=${geolocation.longitude}`;
		}
	}

	const { data } = await axios.get(path, { crossdomain: true });

	return data;
};
