import React from 'react';
import { useState } from 'react';
import Modal from 'react-responsive-modal';

const FeedbackModal = ({ promoCode, promoCodeDiscountValue }) => {
	const [openLocal, setOpenLocal] = useState(true);
	return (
		<div className="feedback-modal-container">
			<Modal
				classNames={{
					modal: `feedback-modal coupon-code`,
					closeButton: 'close-btn',
				}}
				open={promoCode && openLocal}
				onClose={() => setOpenLocal(false)}>
				<div className="inner">
					<div className="icon">
						<i className="fas fa-tags"></i>
					</div>
					<div className="content">
						<h3>{promoCodeDiscountValue ? `${promoCodeDiscountValue}` : '25$'} Discount Coupon Code</h3>
						<p>
							You can get an additional{' '}
							{promoCodeDiscountValue ? `${promoCodeDiscountValue} off` : '25$ off'} if you apply this
							coupon code at the checkout page.
						</p>
						<span className="coupon-code-items">{promoCode || 'TV25'}</span>
						<div className="buttons">
							<button className="btn btn-confirm" onClick={() => setOpenLocal(false)}>
								<span>Ok</span>
							</button>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default FeedbackModal;
