import axios from 'axios';
import * as rax from 'retry-axios';

import config from '../redux/actions/config';

const apiPath = config.apiUrl;
const interceptorId = rax.attach();

export default async function getGoogleCriteronProps(crit) {
	var result = null;
	var errorAxios = null;

	var path = `${apiPath}/zipcodes/geotarget/${crit}`;

	await axios
		.get(
			path, // TODO: Make GoogleData an options later.. but for now test and build away
			{ crossdomain: true }
		)
		.then(response => {
			result = response.data;
		})
		.catch(error => {
			errorAxios = error;
		});

	return { zipCodes: result, error: errorAxios };
}
