import isEmpty from 'lodash/isEmpty';
import ReactHtmlParser from 'react-html-parser';

// TODO: why do we need this? (V.T. 20.12.2021)
export default function HtmlToReact(html) {
	if (!html) {
		return null;
	}
	return ReactHtmlParser(html, {
		transform: node => {
			if (node.type === 'script') {
				if (!isEmpty(node.children)) {
					return '';
				} else {
					return '';
				}
			}
		},
	});
}
