import Image from 'next/image';
import React from 'react';

import fitment from '../../../static/images/icon/landing/fitment.svg';
import freeShipping from '../../../static/images/icon/landing/free-shipping.svg';
import jumpstart from '../../../static/images/icon/landing/jumpstart.svg';
import specialOnlineOnlyPrices from '../../../static/images/icon/landing/special-online-prices.svg';
import warranty from '../../../static/images/icon/landing/warranty.svg';

const WhyBulletPoints = props => {
	var { title, masterProvider } = props;

	if (masterProvider && masterProvider.isActive == true) {
		return '';
	}

	var titleMastHead = title ? title : 'Why Choose Us';

	var priceMessage = 'Cheaper then any Dealership';
	if (titleMastHead == 'Jumpstart Upgrade') {
		priceMessage =
			'Batteries over 2 years old and require a jumpstart need a battery over 40% of the time.  Save Now!';
	}
	return props.isVertical ? (
		<div className={`why-1800-battery v2 vertical`}>
			<h3>Why Choose 1800Battery</h3>
			<div className="item">
				<i className="fas fa-check-circle"></i>
				<h5>{priceMessage}</h5>
			</div>

			<div className="item">
				<i className="fas fa-check-circle"></i>
				<h5>Installation is included in Price</h5>
			</div>

			<div className="item">
				<i className="fas fa-check-circle"></i>
				<h5>Home of the JumpStart Guarantee</h5>
			</div>

			<div className="item">
				<i className="fas fa-check-circle"></i>
				<h5>Correct Battery Guaranteed*</h5>
			</div>

			<small>
				<b>*</b> - Additional Fees May Apply
			</small>
		</div>
	) : (
		<div className={`why-1800-battery v2 ${props.isVertical ? 'vertical' : ''}`}>
			<h2>{titleMastHead}</h2>
			<div className="row">
				<div className="col">
					<div className="item">
						<div className="icon-container">
							<Image
								objectFit="contain"
								width="64px"
								height="64px"
								src={specialOnlineOnlyPrices}
								alt="Special Online Only Prices"
							/>
						</div>
						<h5>{priceMessage}</h5>
					</div>
				</div>
				<div className="col">
					<div className="item">
						<div className="icon-container">
							<Image objectFit="contain" width="64px" height="64px" src={warranty} alt="Warranty" />
						</div>
						<h5>National Warranty Support</h5>
					</div>
				</div>

				<div className="col">
					<div className="item">
						<div className="icon-container">
							<Image
								objectFit="contain"
								width="64px"
								height="64px"
								src={freeShipping}
								alt="Free Shipping"
							/>
						</div>
						<h5>Installation is included in Price</h5>
					</div>
				</div>

				<div className="col">
					<div className="item">
						<div className="icon-container">
							<Image
								objectFit="contain"
								width="64px"
								height="64px"
								src={jumpstart}
								alt="JumpStart Guarantee"
							/>
						</div>
						<h5>For Mobile Delivery of Car Batteries - Home of the &ldquo;JumpStart Guarantee&ldquo;</h5>
					</div>
				</div>

				<div className="col">
					<div className="item">
						<div className="icon-container">
							<Image
								objectFit="contain"
								width="64px"
								height="64px"
								src={fitment}
								alt="Fitment Guarantee"
							/>
						</div>
						<h5>Correct Battery Guaranteed* - We will make sure its the right battery!</h5>
					</div>
				</div>
			</div>
			<h6 className="text-center mt-3">
				<i className="fad fa-info-circle"></i> Additional fees may apply
			</h6>
		</div>
	);
};

export default WhyBulletPoints;
