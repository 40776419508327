export const SET_FILTER = 'filters/SET_FILTER';
export const RESET_FILTER = 'filters/RESET_FILTER';

const setFilter = filter => ({
	type: SET_FILTER,
	payload: {
		filter,
	},
});

const resetFilter = () => ({
	type: RESET_FILTER,
});

export const setFilterValue = filter => async dispatch => {
	dispatch(setFilter(filter));
};
