import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import rootReducer from './rootreducer';

const SET_CLIENT_STATE = 'SET_CLIENT_STATE';

const isDev = process.env.NODE_ENV === 'development';
const composeEnhancers = composeWithDevTools({
	trace: true,
	traceLimit: 25,
});

const enhancer = compose(composeEnhancers(applyMiddleware(thunkMiddleware)));

const makeConfiguredStore = (reducer, initialState) => createStore(reducer, initialState, compose(enhancer));

export const makeStore = (initialState, isServer) => {
	if (isServer) {
		return makeConfiguredStore(rootReducer, initialState);
	} else {
		const { persistStore, persistReducer, createMigrate } = require('redux-persist');

		const migrations = {
			0: state => {
				return {
					...state,
					category: {
						...state.category,
						services: [],
					},
				};
			},
			1: state => {
				return {
					...state,
					priceLevel: null,
				};
			},
			2: state => {
				return {
					...state,
					roadside: null,
				};
			},
		};

		const storage = require('redux-persist/lib/storage').default;
		const expireReducer = require('redux-persist-expire');

		const persistConfig = {
			transforms: [
				// Can add encryptor too
				expireReducer('services', {
					expireSeconds: 3600,
					autoExpire: true,
					expiredState: { services: [] },
				}),
				expireReducer('provider', {
					expireSeconds: 3600,
					autoExpire: true,
					expiredState: { serviceId: null, providerId: null },
				}),
				expireReducer('customer', {
					expireSeconds: 36000,
					autoExpire: true,
				}),
				expireReducer('roadside', {
					expireSeconds: 36000,
					autoExpire: true,
				}),
				expireReducer('filters', {
					expireSeconds: 36000,
					autoExpire: true,
					expiredState: { services: [], categories: [], brands: [] },
				}),
				expireReducer('cartlist', {
					expireSeconds: 1209600, //Two Weeks
					autoExpire: true,
					expiredState: { cart: [], bigCommerceCart: null, cartStep: 0 },
				}),
			],
			key: 'root',
			storage,
			blacklist: ['product', 'category'],
			version: 0,
			migrate: createMigrate(migrations, { debug: isDev }),
			writeFailHandler: error => console.log('ERROR PERSISTING DATA', error),
		};

		const persistedReducer = persistReducer(persistConfig, rootReducer);
		const store = makeConfiguredStore(persistedReducer, initialState);

		store.__PERSISTOR = persistStore(store);

		return store;
	}
};

export const setClientState = clientState => ({
	type: SET_CLIENT_STATE,
	payload: clientState,
});
