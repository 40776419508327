import getGoogleCriteronProps from '@src/functions/getGoogleCriteronProps';
import getZipCodeProps from '@src/functions/getZipCodeProps';
import Cookies from 'js-cookie';

import { isBrowser } from './is-browser';

export const getLocation = () => {
	const geolocation = isBrowser() && window.localStorage.getItem('_mr_geoidx');
	if (geolocation) {
		try {
			return JSON.parse(geolocation);
		} catch (err) {
			console.log(err);
			return null;
		}
	}
	return null;
};

export const setLocation = geolocation => {
	Cookies.set('_mr_geoidx', JSON.stringify(geolocation), { expires: 365 });

	isBrowser() && window.localStorage.setItem('_mr_geoidx', JSON.stringify(geolocation));
};

export const removeLocation = () => {
	Cookies.remove('_mr_geoidx');
	isBrowser() && window.localStorage.removeItem('_mr_geoidx');
};

export const fetchCritAndZip = async (code, isCrit) => {
	if (code) {
		const zip_codes = await (isCrit ? getGoogleCriteronProps : getZipCodeProps)(code);

		if (zip_codes && zip_codes.zipCodes && zip_codes.zipCodes[0]) {
			return {
				latitude: parseFloat(zip_codes.zipCodes[0].latitude),
				longitude: parseFloat(zip_codes.zipCodes[0].longitude),
				zipcode: zip_codes.zipCodes[0].zipcode,
				address: null,
				city: zip_codes.zipCodes[0].cityName,
				state: zip_codes.zipCodes[0].stateAbbr,
				country: 'US',
				formatted_address: `${zip_codes.zipCodes[0].cityName}, ${zip_codes.zipCodes[0].stateAbbr}`,
				formattedAddress: `${zip_codes.zipCodes[0].cityName}, ${zip_codes.zipCodes[0].stateAbbr}`,
			};
		}

		return null;
	}
};
